body{
  font: 16/1920*100vw $font-avenir;
  color: $col-white;
  overflow-x: hidden;
  background-color: #101922;
  @media (max-width: 1199px) {
    font-size: 16px;
  }
  &.active-menu{
    @media (max-width: 991px) {
      overflow: hidden;
    }
  }
}
.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5/1920*100vw;
  padding: 0 10/1920*100vw;
  transition: all .3s ease-in-out;
  position: relative;
  cursor: pointer;
  @media (max-width: 1199px) {
    border-radius: 5px;
    padding: 0 10px;
  }
  &__icon--r{
    span{
      width: 100%;
      text-align: center;
    }
  }
  &__icon{
    i{
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  &__blue{
    background-color: $col-blue;
    color: $col-white;
    border: 2px solid $col-blue;
    &:hover{
      background-color: transparent;
      color: $col-blue;
    }
  }
  &__trans{
    background-color: transparent;
    color: $col-blue;
    border: 2px solid $col-blue;
    &:hover{
      background-color: $col-blue;
      color: $col-white;
    }
  }
  &__gradient{
    background: linear-gradient(28deg, #e637fe 25%, #3092fe 50%, #e637fe 100%) 0 0/200%;
    color: $col-white;
    &:hover{
      background-position: 100% 0;
    }
  }
  &__gray{
    background-color: #43576e;
    color: $col-white;
    border: 2px solid #43576e;
    &:hover{
      background-color: transparent;
      color: #43576e;
    }
  }
}
.align-item-center{
  align-items: center;
}
.tab-content{
  display: none;
  &:nth-of-type(1){
    display: block;
  }
}
.block-title{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
  &__violet{
    span{
      color: $col-violet;
      &.line-animation{
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-background-size: 120px;
        background-image: linear-gradient(-100deg,
                $col-violet,
                rgba(255, 255, 255, .7) 3px,
                rgba(255, 255, 255, .7) 8px,
                $col-violet 9px);
        animation: light-line 6s linear infinite;
      }
    }
  }
  &__green{
    span{
      color: $col-green;
    }
  }
  &__r{
    text-align: right;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  h2{
    font: 50/1920*100vw $font-bebas;
    @media (max-width: 1199px) {
      font-size: 36px;
    }
  }
  h3{
    font: 36/1920*100vw $font-bebas;
    @media (max-width: 1199px) {
      font-size: 26px;
    }
  }
  p{
    font: 21/1920*100vw $font-bebas;
    color: #9da3ac;
    @media (max-width: 1199px) {
      font-size: 18px;
    }
  }
  i{
    color: $col-yellow;
  }
}
.block-title__r--top{
  display: inline-flex;
  align-items: center;
  .coin{
    width: 66/1920*100vw;
    @media (max-width: 1199px) {
      width: 42px;
    }
  }
  .money{
    width: 63/1920*100vw;
    margin: -42/1920*100vw 0 0 8/1920*100vw;
    @media (max-width: 1199px) {
      width: 40px;
      margin: -20px 0 0 8px;
    }
  }
}
.mb60{
  margin-bottom: 60/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 30px;
  }
}
.mb50{
  margin-bottom: 50/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 20px;
  }
}
.mt20{
  margin-top: 22/1920*100vw;
  @media (max-width: 1199px) {
    margin-top: 10px;
  }
}
.color-green{
  color: $col-green;
}
.list-popups{
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: decimal;
  li{
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
}
.text-center{
  text-align: center;
}
.line-effect{
  position: relative;
  img{
    animation: 3s linear line-effect infinite;
  }
}
.shake-anim{
  animation: 3s linear tada infinite;
}