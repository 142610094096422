.block-banner{
  padding: 15/1920*100vw 0 104/1920*100vw;
  background: url("../images/bg/bg-banner.png") top center/cover no-repeat;
  @media (max-width: 1199px) {
    padding: 15px 0 80px;
  }
}
.tab-content__banner{
  &:nth-of-type(1){
    .banner-text {
      span{
        color: $col-violet;
      }
      h2 i{
        -webkit-text-stroke-color: $col-violet;
      }
    }
  }
  &:nth-of-type(2){
    .banner-text {
      span{
        color: $col-green;
      }
      h2 i{
        -webkit-text-stroke-color: $col-green;
      }
    }
  }
}
.banner-l{
  margin-bottom: 120/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 0;
  }
}
.banner-text{
  @media (max-width: 1199px) {
    text-align: center;
  }
  h3{
    text-transform: uppercase;
    font: 88/1920*100vw $font-bebas;
    color: #495a71;
    -webkit-text-stroke: 2px rgba(255, 255, 255, .34);
    -webkit-text-fill-color: transparent;
    @media (max-width: 1199px) {
      font-size: 46px;
    }
  }
  h2{
    font: 96/1920*100vw $font-bebas;
    @media (max-width: 1199px) {
      font-size: 54px;
    }
    i{
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
    }
  }
}
.banner-time{
  display: flex;
  align-items: center;
  @media (max-width: 1199px) {
    justify-content: center;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
  h4{
    text-transform: uppercase;
    font: 48/1920*100vw $font-bebas;
    margin-right: 12/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 32px;
      margin: 0 10px 10px 0;
    }
  }
}
.timer{
  display: flex;
}
.timer-box{
  margin: 0 4/1920*100vw 8/1920*100vw;
  @media (max-width: 1199px) {
    margin: 0 2px 8px;
  }
  p{
    text-align: center;
    color: $col-grey;
    font-size: 17/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 15px;
    }
  }
}
.timer-num{
  display: flex;
  span{
    margin: 0 2.5/1920*100vw;
    height: 81/1920*100vw;
    width: 59/1920*100vw;
    background: url("../images/other/timer-bg.png") center/contain no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 50/1920*100vw $font-bebas;
    @media (max-width: 1199px) {
      margin: 0 2px;
      height: 50px;
      width: 38px;
      font-size: 32px;
    }
  }
}
.banner-winners{
  display: flex;
  @media (max-width: 1199px) {
    justify-content: center;
    padding-right: 70px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    padding-right: 0;
  }
}
.banner-winners__r{
  position: relative;
  width: 100%;
  @media (max-width: 1199px) {
    width: auto;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
}
.winner-img{
  width: 643/1920*100vw;
  flex: 0 0 643/1920*100vw;
  display: none;
  position: relative;
  @media (max-width: 1199px) {
    width: 440px;
    flex: 0 0 440px;
  }
  @media (max-width: 767px) {
    flex: 0 0 auto;
    width: 100%;
  }
  &:nth-of-type(1){
    display: block;
  }
  &__1{
    .parallax-icon{
      &:nth-of-type(1){
        top: 9/1920*100vw;
        right: 161/1920*100vw;
        width: 96/1920*100vw;
      }
      &:nth-of-type(2){
        top: 248/1920*100vw;
        right: 57/1920*100vw;
        width: 72/1920*100vw;
      }
      &:nth-of-type(3){
        top: 177/1920*100vw;
        right: 121/1920*100vw;
        width: 62/1920*100vw;
      }
      &:nth-of-type(4){
        bottom: 240px;
        right: 100px;
        width: 62/1920*100vw;
      }
      &:nth-of-type(5){
        top: 41px;
        left: 95px;
        width: 98/1920*100vw;
      }
      &:nth-of-type(6){
        bottom: -12/1920*100vw;
        left: 16/1920*100vw;
        width: 460/1920*100vw;
        z-index: 2;
        @media (max-width: 1199px) {
          display: block;
          width: 287/440*100%;
          bottom: -6/440*100%;
          left: 29/440*100%;
        }
      }
    }
  }
  &__2{
    .parallax-icon{
      &:nth-of-type(1){
        bottom: 160/1920*100vw;
        right: 38/1920*100vw;
        width: 90/1920*100vw;
      }
      &:nth-of-type(2){
        top: 179/1920*100vw;
        right: 0;
        width: 67/1920*100vw;
      }
      &:nth-of-type(3){
        top: 0;
        right: 121/1920*100vw;
        width: 68/1920*100vw;
      }
      &:nth-of-type(4){
        top: 20/1920*100vw;
        left: 151/1920*100vw;
        width: 60/1920*100vw;
      }
      &:nth-of-type(5){
        top: 135/1920*100vw;
        left: 27/1920*100vw;
        width: 61/1920*100vw;
      }
      &:nth-of-type(6){
        bottom: 21px;
        left: 24/1920*100vw;
        width: 512/1920*100vw;
        z-index: 2;
        @media (max-width: 1199px) {
          display: block;
          bottom: 16/440*100%;
          left: 25/440*100%;
          width: 336/440*100%;
        }
      }
    }
  }
}
.parallax-icon{
  position: absolute;
  @media (max-width: 1199px) {
    display: none;
  }
}
.top-users{
  counter-reset: position;
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.winner-user{
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  @media (max-width: 767px) {
    position: static;
    margin: 0 10px 30px;
  }
  &:nth-child(1){
    top: 13.5%;
    left: -5/1920*100vw;
    @media (max-width: 1199px) {
      top: 8%;
      left: -15px;
    }
    .user-photo{
      @media (min-width: 1200px) {
        flex: 0 0 72/1920*100vw;
        height: 72/1920*100vw;
      }
      &:before{
        @media (min-width: 1200px) {
          width: 17/1920*100vw;
          height: 23/1920*100vw;
          font-size: 13.4/1920*100vw;
        }
        background-color: #f6aa20;
      }
      &:after{
        content: '';
        position: absolute;
        bottom: 105%;
        left: 50%;
        transform: translateX(-50%);
        width: 38/1920*100vw;
        height: 38/1920*100vw;
        background: url("../images/icons/top-icon.png") center/contain no-repeat;
        @media (max-width: 1199px) {
          width: 26px;
          height: 26px;
        }
      }
      img{
        animation: 2s ease-in-out 1s top-shadow infinite;
      }
    }
  }
  &:nth-child(2){
    top: 29%;
    left: 50/1920*100vw;
    @media (max-width: 1199px) {
      top: 25%;
      left: 30px;
    }
    .user-photo{
      @media (min-width: 1200px) {
        flex: 0 0 69/1920*100vw;
        height: 69/1920*100vw;
      }
      &:before{
        @media (min-width: 1200px) {
          width: 16/1920*100vw;
          height: 22/1920*100vw;
          font-size: 12.66/1920*100vw;
        }
        background-color: #93aab5;
      }
    }
  }
  &:nth-child(3){
    top: 46%;
    left: 74/1920*100vw;
    @media (max-width: 1199px) {
      top: 42%;
      left: 56px;
    }
    .user-photo{
      @media (min-width: 1200px) {
        flex: 0 0 63/1920*100vw;
        height: 63/1920*100vw;
      }
      &:before{
        @media (min-width: 1200px) {
          width: 15/1920*100vw;
          height: 20/1920*100vw;
          font-size: 11.6/1920*100vw;
        }
        background-color: #b97625;
      }
    }
  }
  &:nth-child(4){
    top: 62%;
    left: 60/1920*100vw;
    @media (max-width: 1199px) {
      top: 58%;
      left: 40px;
    }
  }
  &:nth-child(5){
    top: 74%;
    left: 9/1920*100vw;
    @media (max-width: 1199px) {
      left: 0;
    }
  }
}
.user-photo{
  flex: 0 0 56/1920*100vw;
  width: 56/1920*100vw;
  height: 56/1920*100vw;
  position: relative;
  @media (max-width: 1199px) {
    flex: 0 0 56px;
    height: 56px;
    width: 56px;
  }
  &:before{
    content: counter(position);
    counter-increment: position;
    position: absolute;
    top: 95%;
    left: 50%;
    width: 13/1920*100vw;
    height: 18/1920*100vw;
    background-color: #415d8f;
    transform: translateX(-50%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 85%, 0 100%);
    font: 10/1920*100vw $font-relewayMedium;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1199px) {
      width: 13px;
      height: 18px;
      font-size: 10px;
    }
  }
  img{
    border: 3/1920*100vw solid #28406e;
    border-radius: 50%;
    @include cover-style-img;
    @media (max-width: 1199px) {
      border-width: 3px;
    }
  }
}
.user-info{
  padding-left: 18/1920*100vw;
  white-space: nowrap;
  font: 15/1920*100vw $font-relewayMedium;
  position: relative;
  z-index: 2;
  @media (max-width: 1199px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    display: none;
  }
  p{
    color: #ffd22e;
  }
}
.all-users{
  position: absolute;
  top: 84%;
  left: -50/1920*100vw;
  font-family: $font-relewaySemiBold;
  background-color: #9037d5;
  border-radius: 50%;
  width: 40/1920*100vw;
  height: 40/1920*100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
  @media (max-width: 1199px) {
    top: 88%;
    left: -40px;
    width: 40px;
    height: 40px;
  }
  @media (max-width: 767px) {
    position: static;
  }
  &:hover{
    background-color: $col-violet;
  }
}
.banner-menu{
  margin-top: -135/1920*100vw;
  display: flex;
  @media (max-width: 1199px) {
    margin-top: 20px;
    justify-content: center;
  }
  li{
    padding: 0 30/1920*100vw 14/1920*100vw;
    text-transform: uppercase;
    font: 21/1920*100vw $font-bebas;
    color: $col-grey;
    cursor: pointer;
    position: relative;
    transition: color .3s ease-in-out;
    @media (max-width: 1199px) {
      padding: 0 20px 10px;
      font-size: 19px;
    }
    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      left: 0;
      background-image: linear-gradient(to right,
              rgba(255, 255, 255, .1),
              rgba(255, 255, 255, .56),
              rgba(255, 255, 255, .1));
    }
    &:first-child{
      padding-left: 0;
    }
    &:last-child{
      padding-right: 0;
    }
    &:hover{
      color: $col-white;
    }
    &.active{
      color: $col-white;
      &:first-child span:before{
        background-color: $col-violet;
      }
      &:last-child span:before{
        background-color: $col-green;
      }
    }
  }
  span{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      bottom: -14/1920*100vw;
      width: 5/1920*100vw;
      height: 5/1920*100vw;
      transform: translate(-50%, 50%);
      left: 50%;
      background-color: #9da3ac;
      z-index: 2;
      @media (max-width: 1199px) {
        bottom: -10px;
        width: 5px;
        height: 5px;
      }
    }
  }
}