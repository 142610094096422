@mixin cover-style-img{
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

@mixin text-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin popupAnimationTop{
  visibility: hidden;
  opacity: 0;
  transform-origin: top;
  transform: scaleY(0);
  transition: all .3s ease-in-out;
  &.active{
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
  }
}
@mixin btn($w, $h, $fs, $ff){
  width: $w;
  height: $h;
  font: $fs $ff;
}
@keyframes top-shadow{
  from{
    box-shadow: 0 0 0 0 #192d51;
  }
  to{
    box-shadow: 0 0 0 33/1920*100vw rgba(25, 45, 81, .5);
    @media (max-width: 1199px) {
      box-shadow: 0 0 0 18px rgba(25, 45, 81, .5);
    }
  }
}
@keyframes line-effect{
  from{
    filter: brightness(.8)
  }
  50%{
    filter: brightness(1.5)
  }
  to{
    filter: brightness(.8)
  }
}
@keyframes light-line{
  from{
    background-position: -80px 0;
  }
  to{
    background-position: 280px 0;
  }
}