.game-header{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20/1920*100vw 40/1920*100vw;
  background-color: #2d3645;
  @media (max-width: 1199px) {
    padding: 15px;
  }
  @media (max-width: 767px) {
    justify-content: space-between;
  }
}
.game-btn{
  width: 60/1920*100vw;
  height: 60/1920*100vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18/1920*100vw;
  cursor: pointer;
  border: 5/1920*100vw solid;
  color: $col-white;
  transition: all .3s ease-in-out;
  @media (max-width: 1199px) {
    width: 40px;
    height: 40px;
    font-size: 16px;
    border-width: 4px;
  }
  &:hover{
    box-shadow: none;
  }
  &__pause{
    background-color: #fec30a;
    border-color: #ffc806;
    box-shadow: 0 0 21/1920*100vw #fec30a;
    @media (max-width: 1199px) {
      box-shadow: 0 0 16px #fec30a;
    }
    @media (max-width: 767px) {
      order: 2;
    }
  }
  &__volume{
    background-color: #009fe2;
    border-color: #00aded;
    box-shadow: 0 0 21/1920*100vw #009fe2;
    @media (max-width: 1199px) {
      box-shadow: 0 0 16px #009fe2;
    }
    @media (max-width: 767px) {
      order: 3;
    }
  }
  &__close{
    background-color: #e1395c;
    border-color: #dd2049;
    box-shadow: 0 0 21/1920*100vw #e1395c;
    @media (max-width: 1199px) {
      box-shadow: 0 0 16px #e1395c;
    }
    @media (max-width: 767px) {
      order: 3;
    }
  }
}
.game-score{
  flex: 1 0 auto;
  font: 48/1920*100vw $font-bebas;
  padding-left: 137/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 26px;
    padding-left: 40px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  span{
    color: $col-green;
  }
  &.text-center{
    padding: 0;
  }
}
.game-timer{
  margin-right: 160/1920*100vw;
  @media (max-width: 1199px) {
    margin-right: 40px;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    width: 100%;
  }
  .timer-num span{
    background: url("../images/other/timer-bg-dark.png") center/contain no-repeat;
  }
}