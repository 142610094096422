.popup-text{
  font-family: $font-bebas;
  margin-bottom: 20/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
  h2{
    font-size: 50/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 32px;
    }
  }
  h3{
    font-size: 30/1920*100vw;
    line-height: 1;
    @media (max-width: 1199px) {
      font-size: 22px;
    }
  }
  p{
    font-size: 21/1920*100vw;
    color: #9da3ac;
    letter-spacing: 1px;
    @media (max-width: 1199px) {
      font-size: 18px;
    }
  }
}
.btn-register{
  @include btn(196/1920*100vw, 56/1920*100vw, 24/1920*100vw, $font-bebas);
  margin-bottom: 15/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
    @include btn(160px, 46px, 18px, $font-bebas);
  }
}
.popup-welcome{
  font: 14/1920*100vw $font-bebas;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.5px;
  margin-bottom: 25/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 14px;
    margin-bottom: 15px;
  }
  img{
    margin-left: 5/1920*100vw;
    width: 24/1920*100vw;
    @media (max-width: 1199px) {
      margin-left: 5px;
      width: 20px;
    }
  }
}
.popup-thank{
  font: 21/1920*100vw $font-bebas;
  color: $col-grey;
  @media (max-width: 1199px) {
    font-size: 18px;
  }
  a{
    color: $col-green;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}