footer{
  padding: 38/1920*100vw 0 25/1920*100vw;
  background-color: #202936;
  @media (max-width: 1199px) {
    padding: 20px 0;
  }
}
.footer-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 160/1920*100vw;
  @media (max-width: 1199px) {
    padding: 0;
  }
  @media (max-width: 991px) {
    flex-direction: column;
  }
}
.footer-l{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-logo{
  width: 160/1920*100vw;
  margin-bottom: 15/1920*100vw;
  @media (max-width: 1199px) {
    width: 120px;
    margin-bottom: 10px;
  }
}
.soc{
  display: flex;
  a{
    margin: 0 3/1920*100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38/1920*100vw;
    height: 38/1920*100vw;
    border-radius: 5/1920*100vw;
    background-color: #313f53;
    transition: all .3s ease-in-out;
    @media (max-width: 1199px) {
      margin: 0 3px;
      width: 38px;
      height: 38px;
      border-radius: 5px;
    }
    &:hover{
      color: $col-violet;
    }
  }
}
.footer-widgets{
  width: 720/1920*100vw;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1199px) {
    width: 60%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 567px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.footer-menu{
  padding-top: 20/1920*100vw;
  @media (max-width: 1199px) {
    padding-top: 15px;
  }
  li{
    margin-bottom: 20/1920*100vw;
    @media (max-width: 1199px) {
      margin-bottom: 10px;
    }
  }
  a{
    font-size: 18/1920*100vw;
    color: rgba(255, 255, 255, .57);
    transition: color .3s ease-in-out;
    @media (max-width: 1199px) {
      font-size: 16px;
    }
    &:hover{
      color: $col-violet;
    }
  }
}
.copyright{
  margin-top: 35/1920*100vw;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, .25);
  @media (max-width: 1199px) {
    padding-top: 20px;
  }
}