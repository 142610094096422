.current-info{
  p{
    font: 36/1920*100vw $font-bebas;
    @media (max-width: 1199px) {
      font-size: 24px;
    }
  }
}
.current-score{
  span{
    color: $col-green;
  }
}
.current-position{
  margin-bottom: 20/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
  span{
    color: $col-yellow;
  }
}
.btn-continue{
  @include btn(167/1920*100vw, 55/1920*100vw, 24/1920*100vw, $font-bebas);
  @media (max-width: 1199px) {
    @include btn(140px, 42px, 18px, $font-bebas);
  }
}
.reward-info{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
  img{
    width: 36/1920*100vw;
    margin-left: 10/1920*100vw;
    @media (max-width: 1199px) {
      width: 24px;
      margin-left: 8px;
    }
  }
}