@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/BebasNeue-Regular.eot');
  src: url('../fonts/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/BebasNeue-Regular.woff2') format('woff2'),
  url('../fonts/BebasNeue-Regular.woff') format('woff'),
  url('../fonts/BebasNeue-Regular.ttf') format('truetype'),
  url('../fonts/BebasNeue-Regular.svg#BebasNeue-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../fonts/AvenirNextCyr-Regular.eot');
  src: url('../fonts/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr-Regular.woff2') format('woff2'),
  url('../fonts/AvenirNextCyr-Regular.woff') format('woff'),
  url('../fonts/AvenirNextCyr-Regular.ttf') format('truetype'),
  url('../fonts/AvenirNextCyr-Regular.svg#AvenirNextCyr-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway Medium';
  src: url('../fonts/Raleway-Medium.eot');
  src: url('../fonts/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Raleway-Medium.woff2') format('woff2'),
  url('../fonts/Raleway-Medium.woff') format('woff'),
  url('../fonts/Raleway-Medium.ttf') format('truetype'),
  url('../fonts/Raleway-Medium.svg#Raleway-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway SemiBold';
  src: url('../fonts/Raleway-SemiBold.eot');
  src: url('../fonts/Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Raleway-SemiBold.woff2') format('woff2'),
  url('../fonts/Raleway-SemiBold.woff') format('woff'),
  url('../fonts/Raleway-SemiBold.ttf') format('truetype'),
  url('../fonts/Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Medium';
  src: url('../fonts/AvenirNextCyr-Medium.eot');
  src: url('../fonts/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr-Medium.woff2') format('woff2'),
  url('../fonts/AvenirNextCyr-Medium.woff') format('woff'),
  url('../fonts/AvenirNextCyr-Medium.ttf') format('truetype'),
  url('../fonts/AvenirNextCyr-Medium.svg#AvenirNextCyr-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Demi';
  src: url('../fonts/AvenirNextCyr-Demi.eot');
  src: url('../fonts/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AvenirNextCyr-Demi.woff2') format('woff2'),
  url('../fonts/AvenirNextCyr-Demi.woff') format('woff'),
  url('../fonts/AvenirNextCyr-Demi.ttf') format('truetype'),
  url('../fonts/AvenirNextCyr-Demi.svg#AvenirNextCyr-Demi') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?i60y4o');
  src:  url('../fonts/icomoon.eot?i60y4o#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?i60y4o') format('truetype'),
  url('../fonts/icomoon.woff?i60y4o') format('woff'),
  url('../fonts/icomoon.svg?i60y4o#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon_2.eot?rj4ibu');
  src:  url('../fonts/icomoon_2.eot?rj4ibu#iefix') format('embedded-opentype'),
  url('../fonts/icomoon_2.ttf?rj4ibu') format('truetype'),
  url('../fonts/icomoon_2.woff?rj4ibu') format('woff'),
  url('../fonts/icomoon_2.svg?rj4ibu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye:before {
  content: "\e9ce";
}
.icon-checkmark:before {
  content: "\e906";
}
.icon-user:before {
  content: "\e916";
}
.icon-arrow-left:before {
  content: "\e907";
}
.icon-arrow-right:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e909";
}
.icon-pause:before {
  content: "\e90a";
}
.icon-volume:before {
  content: "\e90b";
}
.icon-linkedin:before {
  content: "\e90c";
}
.icon-youtube:before {
  content: "\e90d";
}
.icon-apple:before {
  content: "\e90e";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-twitter:before {
  content: "\e903";
}
.icon-googleplay:before {
  content: "\e90f";
  color: #607d8b;
}
.icon-bell-o:before {
  content: "\e910";
}
.icon-clear:before {
  content: "\e911";
}
.icon-timer:before {
  content: "\e901";
}
.icon-person_add_alt:before {
  content: "\e912";
}
.icon-video:before {
  content: "\e913";
}
.icon-play:before {
  content: "\e902";
}
.icon-mail-envelope-closed:before {
  content: "\e914";
}
.icon-pen-angled:before {
  content: "\e915";
}
.icon-lock:before {
  content: "\e900";
}
