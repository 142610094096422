.live-icon{
  position: absolute;
  top: 13/1920*100vw;
  left: 13/1920*100vw;
  width: 109/1920*100vw;
  animation: 1s linear pulse infinite;
  @media (max-width: 1199px) {
    top: 10px;
    left: 10px;
    width: 109px;
  }
  @media (max-width: 567px) {
    width: 50px;
  }
}
.popup-timer{
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 567px) {
    flex-direction: column;
  }
  &__mb25{
    margin-bottom: 25/1920*100vw;
    @media (max-width: 1199px) {
      margin-bottom: 15px;
    }
  }
  h5{
    font: 18/1920*100vw $font-bebas;
    color: rgba(255, 255, 255, .5);
    margin: -10/1920*100vw 15/1920*100vw 0 0;
    @media (max-width: 1199px) {
      font-size: 16px;
      margin: -10px 10px 0 0;
    }
    @media (max-width: 567px) {
      margin: 0 0 10px;
    }
  }
  .timer{
    .timer-num span{
      width: 32/1920*100vw;
      height: 42/1920*100vw;
      font-size: 26/1920*100vw;
      margin: 0 1.5/1920*100vw 5/1920*100vw;
      @media (max-width: 1199px) {
        width: 32px;
        height: 42px;
        font-size: 26px;
        margin: 0 1.5px 5px;
      }
    }
    .timer-box{
      margin: 0 2/1920*100vw;
      @media (max-width: 1199px) {
        margin: 0 2px;
      }
      p{
        font-size: 9/1920*100vw;
        @media (max-width: 1199px) {
          font-size: 9px;
        }
      }
    }
  }
}
.popup-subtitle{
  font: 21/1920*100vw $font-bebas;
  margin-bottom: 10/1920*100vw;
  color: #9da3ac;
  @media (max-width: 1199px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.style-slider{
  .slick-disabled{
    display: none !important;
  }
  .slider-arrow__prev{
    left: -26/1920*100vw;
    @media (max-width: 1199px) {
      left: 0;
    }
  }
  .slider-arrow__next{
    right: -26/1920*100vw;
    @media (max-width: 1199px) {
      right: 0;
    }
  }
}
.slider-place{
  margin-bottom: 20/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
}
.slider-place__col{
  padding: 0 9/1920*100vw;
  @media (max-width: 1199px) {
    padding: 5px;
  }
}
.slider-place__item{
  background-color: #273545;
  border-radius: 15/1920*100vw;
  padding: 8/1920*100vw;
  @media (max-width: 1199px) {
    border-radius: 15px;
    padding: 8px;
  }
}
.slider-place__t{
  text-align: left;
  font-family: $font-avenirDemi;
  color: $col-green;
}
.slider-place__img{
  width: 35/1920*100vw;
  margin: 0 auto 5/1920*100vw;
  @media (max-width: 1199px) {
    width: 35px;
    margin-bottom: 5px;
  }
}
.slider-place__money{
  font: 31/1920*100vw $font-bebas;
  color: $col-yellow;
  @media (max-width: 1199px) {
    font-size: 31px;
  }
  span{
    color: $col-white;
  }
}
.slider-users{
  margin-bottom: 15/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
}
.slider-users__col{
  padding: 40/1920*100vw 5/1920*100vw 0;
  @media (max-width: 1199px) {
    padding: 40px 5px 0;
  }
}
.user-item{
  position: relative;
  padding: 36/1920*100vw 10/1920*100vw 10/1920*100vw;
  background-color: #1b2735;
  border-radius: 10/1920*100vw;
  border: 4/1920*100vw solid #28416f;
  height: 112/1920*100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 1199px) {
    padding: 36px 10px 10px;
    border-radius: 10px;
    border-width: 4px;
    height: 112px;
  }
  &.user-item__my{
    background-color: #273545;
    .user-item__name{
      color: $col-yellow;
      font-size: 30/1920*100vw;
      margin-bottom: 0;
      @media (max-width: 1199px) {
        font-size: 30px;
      }
    }
  }
}
.user-item__photo{
  position: absolute;
  top: 0;
  left: 50%;
  width: 63/1920*100vw;
  height: 63/1920*100vw;
  transform: translate(-50%, -50%);
  margin-bottom: 10/1920*100vw;
  @media (max-width: 1199px) {
    width: 63px;
    height: 63px;
    margin-bottom: 10px;
  }
  img{
    border-radius: 50%;
    @include cover-style-img;
  }
}
.user-item__label{
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26/1920*100vw;
  height: 26/1920*100vw;
  position: absolute;
  top: -12/1920*100vw;
  left: -6/1920*100vw;
  background-color: #9037d5;
  font: 11/1920*100vw $font-avenirDemi;
  @media (max-width: 1199px) {
    width: 26px;
    height: 26px;
    top: -12px;
    left: -6px;
    font-size: 11px;
  }
}
.user-item__name{
  @include text-overflow;
  font: 20/1920*100vw $font-bebas;
  margin-bottom: 5/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.user-item__points{
  color: $col-green;
  font: 14/1920*100vw $font-avenirDemi;
  @media (max-width: 1199px) {
    font-size: 14px;
  }
}
.btn-play{
  @include btn(123/1920*100vw, 45/1920*100vw, 20/1920*100vw, $font-bebas);
  @media (max-width: 1199px) {
    @include btn(123px, 45px, 20px, $font-bebas);
  }
}
.btn-play-again{
  @include btn(160/1920*100vw, 45/1920*100vw, 20/1920*100vw, $font-bebas);
  @media (max-width: 1199px) {
    @include btn(160px, 45px, 20px, $font-bebas);
  }
}
.tournament-scored{
  font: 36/1920*100vw $font-bebas;
  margin-bottom: 15/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .green{
    color: $col-green;
  }
  .yellow{
    color: $col-yellow;
  }
}