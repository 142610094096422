.block-tournaments{
  padding: 30/1920*100vw 0;
  position: relative;
  transform-style: preserve-3d;
  @media (max-width: 1199px) {
    padding: 30px 0;
  }
  &:before{
    content: '';
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
    height: 1041/1920*100vw;
    background: url("../images/bg/bg-tournament.png") center/contain no-repeat;
    transform: translateZ(-1px);
    @media (max-width: 1199px) {
      display: none;
    }
  }
}
.tournament{
  position: relative;
  background-color: #1b2633;
  padding: 20/1920*100vw 32/1920*100vw;
  border-radius: 10/1920*100vw;
  @media (max-width: 1199px) {
    padding: 20px 30px;
    border-radius: 10px;
  }
  &:before{
    content: '';
    position: absolute;
    top: 8/1920*100vw;
    left: 8/1920*100vw;
    width: 32/1920*100vw;
    height: 20/1920*100vw;
    background: url("../images/other/bg-tournament.png") center/contain no-repeat;
    @media (max-width: 1199px) {
      top: 5px;
      left: 5px;
      width: 32px;
      height: 20px;
    }
  }
  .btn{
    display: flex;
    margin: 0 auto;
  }
  .btn__blue{
    @include btn(120/1920*100vw, 44/1920*100vw, 20/1920*100vw, $font-bebas);
    box-shadow: 0 0 24/1920*100vw 12/1920*100vw rgba(55, 163, 255, .23);
    @media (max-width: 1199px) {
      @include btn(120px, 44px, 20px, $font-bebas);
      box-shadow: 0 0 24px 12px rgba(55, 163, 255, .23);
    }
    i{
      flex: 0 0 28/1920*100vw;
      height: 28/1920*100vw;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4490f8;
      font-size: 14/1920*100vw;
      @media (max-width: 1199px) {
        font-size: 14px;
        height: 28px;
        flex: 0 0 28px;
      }
    }
    &:hover{
      box-shadow: none;
      i{
        color: $col-white;
      }
    }
  }
  .btn__gray{
    @include btn(160/1920*100vw, 44/1920*100vw, 15/1920*100vw, $font-relewayMedium);
    @media (max-width: 1199px) {
      @include btn(160px, 44px, 15px, $font-relewayMedium);
    }
  }
}
.tournament-jackpot{
  position: absolute;
  top: -10/1920*100vw;
  right: -40/1920*100vw;
  background-color: #273545;
  border-radius: 15/1920*100vw;
  padding: 8/1920*100vw 47/1920*100vw 8/1920*100vw 10/1920*100vw;
  @media (max-width: 1199px) {
    top: -10px;
    right: 0;
    border-radius: 10px;
    padding: 5px 30px 5px 8px;
  }
  h3{
    font: 31/1920*100vw $font-bebas;
    color: $col-yellow;
    line-height: 1;
    @media (max-width: 1199px) {
      font-size: 22px;
    }
    span{
      color: $col-white;
    }
  }
  p{
    color: $col-green;
    font: 13/1920*100vw $font-avenirDemi;
    @media (max-width: 1199px) {
      font-size: 13px;
    }
  }
  img{
    position: absolute;
    width: 61/1920*100vw;
    top: -36/1920*100vw;
    right: -25/1920*100vw;
    @media (max-width: 1199px) {
      width: 50px;
      top: -35px;
      right: -12px;
    }
  }
}
.tournament-img{
  width: 170/1920*100vw;
  height: 170/1920*100vw;
  margin: 0 auto 25/1920*100vw;
  border-radius: 35/1920*100vw;
  overflow: hidden;
  box-shadow: 0 10/1920*100vw 35/1920*100vw rgba(16, 14, 20, .45);
  @media (max-width: 1199px) {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(16, 14, 20, .45);
  }
  &__active{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(18, 222, 78, .7);
    }
  }
  img{
    @include cover-style-img;
  }
}
.tournament-progress{
  position: relative;
  background-color: #10121f;
  border: 1px solid #10121f;
  border-radius: 15/1920*100vw;
  height: 18/1920*100vw;
  margin-bottom: 10/1920*100vw;
  @media (max-width: 1199px) {
    height: 14px;
    border-radius: 15px;
    margin-bottom: 10px;
  }
}
.progress-line{
  position: absolute;
  top: 0;
  left: 0;
  background-color: $col-yellow;
  height: 100%;
  border-radius: 15/1920*100vw;
  @media (max-width: 1199px) {
    border-radius: 15px;
  }
  &:before, &:after{
    position: absolute;
    right: 0;
  }
  &:before{
    content: '\e901';
    top: 0;
    width: 18/1920*100vw;
    height: 18/1920*100vw;
    background-color: #10121f;
    border-radius: 50%;
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'icomoon';
    font-size: 16/1920*100vw;
    animation: 2s linear tada infinite;
    @media (max-width: 1199px) {
      font-size: 12px;
      width: 14px;
      height: 14px;
    }
  }
  &:after{
    content: attr(data-hours);
    font: 11/1920*100vw $font-avenirMedium;
    color: #9bafc8;
    top: -20/1920*100vw;
    transform: translateX(50%);
    width: max-content;
    @media (max-width: 1199px) {
      font-size: 11px;
      top: -18px;
    }
  }
}
.tournament-title{
  text-align: center;
  margin-bottom: 8/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 8px;
  }
  &__uppercase{
    font: 22/1920*100vw $font-bebas;
    @media (max-width: 1199px) {
      font-size: 18px;
    }
  }
  &__standard{
    font: 15/1920*100vw $font-avenirMedium;
    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }
}
.tournament-position{
  text-align: center;
  font: 15/1920*100vw $font-relewayMedium;
  color: #9da3ac;
  margin-bottom: 10/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 14px;
    margin-bottom: 8px;
  }
  span{
    color: $col-white;
    font-family: $font-relewaySemiBold;
  }
}
.tournament-time{
  text-align: center;
  margin-bottom: 15/1920*100vw;
  font: 22/1920*100vw $font-relewayMedium;
  @media (max-width: 1199px) {
    font-size: 18px;
  }
  span{
    color: $col-green;
  }
}
.block-btn{
  text-align: center;
  .btn{
    @include btn(247/1920*100vw, 50/1920*100vw, 22/1920*100vw, $font-bebas);
    @media (max-width: 1199px) {
      @include btn(247px, 50px, 22px, $font-bebas);
    }
  }
}