.fancybox-is-open .fancybox-bg{
  background: #202936;
}
.fancybox-slide--html .fancybox-close-small{
  width: 37/1920*100vw;
  height: 37/1920*100vw;
  padding: 5/1920*100vw;
  background-image: linear-gradient(111deg, #f15050, #f31616);
  border-radius: 50%;
  @media (max-width: 1199px) {
    width: 20px;
    height: 20px;
    padding: 2px;
  }
}
.popup-style{
  padding: 40/1920*100vw;
  background-color: transparent;
  @media (max-width: 1199px) {
    padding: 25px;
  }
  &__w600{
    width: 600/1920*100vw;
    @media (max-width: 1199px) {
      width: 590px;
    }
  }
  &__w900{
    width: 900/1920*100vw;
    @media (max-width: 1199px) {
      width: 870px;
    }
  }
  &__pt{
    padding-top: 55/1920*100vw;
    @media (max-width: 1199px) {
      padding-top: 35px;
    }
  }
  &__text-center{
    text-align: center;
  }
  .btn__icon--r{
    i{
      margin-left: 11/1920*100vw;
      flex: 0 0 34/1920*100vw;
      width: 34/1920*100vw;
      height: 34/1920*100vw;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20/1920*100vw;
      @media (max-width: 1199px) {
        margin-left: 8px;
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        font-size: 14px;
      }
    }
  }
}
.popup-box{
  background: #1c2734 url("../images/bg/bg-popup.png") center top/cover no-repeat;
  padding: 40/1920*100vw;
  border-radius: 20/1920*100vw;
  box-shadow: 0 4/1920*100vw 24/1920*100vw rgba(0, 0, 0, .5);
  position: relative;
  @media (max-width: 1199px) {
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, .5);
  }
}
.popup-top{
  margin-top: -70/1920*100vw;
  @media (max-width: 1199px) {
    margin-top: -40px;
  }
}
.popup-top__img{
  width: 173/1920*100vw;
  height: 173/1920*100vw;
  margin: 0 auto 30/1920*100vw;
  border-radius: 40/1920*100vw;
  border: 7/1920*100vw solid #28416f;
  overflow: hidden;
  @media (max-width: 1199px) {
    width: 110px;
    height: 110px;
    margin: 0 auto 20px;
    border-radius: 20px;
    border-width: 4px;
  }
  &--logo{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom, #cb316a, #9037d5);
    img{
      width: 94/1920*100vw;
      @media (max-width: 1199px) {
        width: 70px;
      }
    }
  }
  &--picture{
    img{
      @include cover-style-img;
    }
  }
}
.popup-title{
  font-family: $font-bebas;
  &__f45{
    font-size: 45/1920*100vw;
    margin-bottom: 30/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 32px;
      margin-bottom: 10px;
    }
  }
  &__f22{
    font-size: 22/1920*100vw;
    margin-bottom: 10/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
.btn-back{
  @include btn(226px, 55px, 24px, $font-bebas);
  @media (max-width: 1199px) {
    @include btn(180px, 46px, 18px, $font-bebas);
  }
}
.btn-watch{
  @include btn(146/1920*100vw, 55/1920*100vw, 24/1920*100vw, $font-bebas);
  margin-bottom: 20/1920*100vw;
  @media (max-width: 1199px) {
    @include btn(120px, 42px, 18px, $font-bebas);
    margin-bottom: 10px;
  }
}