.style-form{
  max-width: 350/1920*100vw;
  margin: 0 auto 35/1920*100vw;
  @media (max-width: 1199px) {
    max-width: 350px;
    margin: 0 auto 15px;
  }
  input:not([type="submit"]){
    display: block;
    width: 100%;
    height: 48/1920*100vw;
    border-radius: 5/1920*100vw;
    background-color: rgba(0, 0, 0, .1);
    text-align: center;
    color: $col-white;
    font-size: 14/1920*100vw;
    margin-bottom: 12/1920*100vw;
    @media (max-width: 1199px) {
      height: 48px;
      border-radius: 5px;
      font-size: 14px;
      margin-bottom: 8px;
    }
    &::placeholder{
      color: $col-grey;
    }
  }
  .form-pay input{
    display: none;
  }
  .btn{
    margin-top: 40/1920*100vw;
    @include btn(200/1920*100vw, 54/1920*100vw, 24/1920*100vw, $font-bebas);
    @media (max-width: 1199px) {
      margin-top: 20px;
      @include btn(160px, 42px, 18px, $font-bebas);
    }
  }
  .avatar{
    input{
      display: none;
    }
  }
  h2{
    font: 22/1920*100vw $font-bebas;
    margin-bottom: 10/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
.field{
  position: relative;
  input{
    padding: 0 10/1920*100vw;
    @media (max-width: 1199px) {
      padding: 0 10px;
    }
  }
  &__icon{
    input{
      padding: 0 40/1920*100vw;
      @media (max-width: 1199px) {
        padding: 0 30px;
      }
    }
  }
  i{
    color: $col-grey;
    position: absolute;
    top: 50%;
    left: 17/1920*100vw;
    transform: translateY(-50%);
    font-size: 16/1920*100vw;
    @media (max-width: 1199px) {
      left: 8px;
      font-size: 16px;
    }
    @media (max-width: 567px) {
      display: none;
    }
  }
}
.avatar{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  cursor: pointer;
  width: 138/1920*100vw;
  height: 138/1920*100vw;
  margin: 0 auto 36/1920*100vw;
  border-radius: 30/1920*100vw;
  border: 7/1920*100vw solid #28416f;
  font-size: 12/1920*100vw;
  color: rgba(122, 133, 149, .5);
  overflow: hidden;
  @media (max-width: 1199px) {
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
    border-radius: 30px;
    border-width: 4px;
    font-size: 12px;
  }
  img{
    @include cover-style-img;
    display: none;
  }
}
.avatar-text{
  padding: 20/1920*100vw;
  @media (max-width: 1199px) {
    padding: 10px;
  }
}
.plus{
  width: 27/1920*100vw;
  height: 27/1920*100vw;
  margin: 0 auto;
  position: relative;
  display: block;
  @media (max-width: 1199px) {
    width: 22px;
    height: 22px;
  }
  &:before, &:after{
    position: absolute;
    content: '';
    width: 100%;
    height: 5/1920*100vw;
    background-color: #656e7c;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border-radius: 5/1920*100vw;
    @media (max-width: 1199px) {
      height: 3px;
      border-radius: 5px;
    }
  }
  &:after{
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
.form-balance{
  font-size: 14/1920*100vw;
  color: $col-green;
  margin-bottom: 40/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.form-pay{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
  @media (max-width: 567px) {
    flex-direction: column;
    align-items: center;
  }
  input:checked ~ .pay-img{
    opacity: 1;
  }
}
.pay-img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 157/1920*100vw;
  height: 60/1920*100vw;
  border-radius: 40/1920*100vw;
  background-color: #bbbec2;
  padding: 0 15/1920*100vw;
  cursor: pointer;
  margin-bottom: 10/1920*100vw;
  opacity: .7;
  @media (max-width: 1199px) {
    margin-bottom: 8px;
    width: 140px;
    height: 46px;
    border-radius: 30px;
    padding: 0 15px;
  }
}
.toggle-password{
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  transform: translateY(-50%);
  color: #7a8595;
  transition: all .3s ease-in-out;
  &:hover{
    color: $col-white;
  }
}