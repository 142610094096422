.block-download{
  padding: 50/1920*100vw 0;
  background: url("../images/bg/bg-download.jpg") left center/cover;
  @media (max-width: 1199px) {
    padding: 30px 0;
  }
  .container{
    position: relative;
  }
}
.download-text{
  width: 530/1920*100vw;
  padding-left: 90/1920*100vw;
  @media (max-width: 1199px) {
    width: 50%;
  }
  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
  }
  h2{
    font: 88/1920*100vw $font-bebas;
    line-height: .9;
    @media (max-width: 1199px) {
      font-size: 44px;
    }
    span{
      -webkit-text-stroke: 1px #babac0;
      -webkit-text-fill-color: transparent;
    }
  }
  h3{
    line-height: .9;
    font: 26/1920*100vw $font-bebas;
    color: rgba(255, 255, 255, .83);
    letter-spacing: 2/1920*100vw;
    margin-bottom: 20/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 15px;
    }
  }
}
.download-icon{
  display: flex;
  margin-bottom: 20/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
  }
  @media (max-width: 991px) {
    justify-content: center;
  }
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66/1920*100vw;
    height: 66/1920*100vw;
    padding: 4/1920*100vw;
    margin-right: 16/1920*100vw;
    border-radius: 5/1920*100vw;
    @media (max-width: 1199px) {
      width: 60px;
      height: 60px;
      padding: 4px;
      margin-right: 10px;
      border-radius: 5px;
    }
  }
  &:last-child{
    margin-right: 0;
  }
}
.download-logo{
  background-color: $col-blue;
}
.download-code{
  background-color: $col-white;
}
.download-btn{
  display: flex;
  @media (max-width: 991px) {
    justify-content: center;
  }
  @media (max-width: 567px) {
    flex-direction: column;
    align-items: center;
  }
  a{
    display: flex;
    @include btn(190/1920*100vw, 52/1920*100vw, 18/1920*100vw, $font-relewaySemiBold);
    margin-right: 30/1920*100vw;
    border: 2px solid $col-blue;
    @media (max-width: 1199px) {
      @include btn(190px, 52px, 18px, $font-relewaySemiBold);
      margin-right: 20px;
    }
    @media (max-width: 567px) {
      margin: 0 0 10px;
    }
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      background-color: $col-white;
      border-color: $col-white;
      color: #101d34;
    }
    i{
      margin-right: 7/1920*100vw;
      @media (max-width: 1199px) {
        margin-right: 7px;
      }
    }
  }
}
.download-img{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 757/1920*100vw;
  @media (max-width: 1199px) {
    width: 45%;
  }
  @media (max-width: 991px) {
    display: none;
  }
}