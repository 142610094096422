$mar: 40/1920*100vw;
$items: 12;
$container: 1610/1920*100vw;

// breakpoints map
$breakpoints: (
        xs: 568px,
        sm: 768px,
        md: 992px,
        lg: 1200px
);

$container-width: (
        540px,
        720px,
        960px,
        $container,
);

@media (min-width: 568px) {
  .container{
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container{
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container{
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container{
    max-width: $container;
  }
}

// media query mixin
@mixin break($size) {
  @media (min-width: map-get($breakpoints, $size)) {
    @content;
  }
}

// grid container

.container{
  padding: 0 $mar;
  margin: 0 auto;
  @media (max-width: 1199px) {
    padding: 0 15px;
  }
}
.row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -$mar;
  margin-right: -$mar;
  @media (max-width: 1199px) {
    margin: 0 -15px;
  }
}

// grid item
[class^="col-"] {
  flex: 0 0 100%;
  padding-left: $mar;
  padding-right: $mar;
  @media (max-width: 1199px) {
    padding: 0 15px;
  }
}

// loop over the breakpoints
@each $key, $value in $breakpoints {
  @for $i from 1 through $items {
    .col-#{$key}-#{$i} {
      @include break($key) {
        flex: 0 0 #{$i / $items * 100%};
      }
    }
  }
}