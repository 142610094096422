.congratulations-subtitle{
  font-size: 14/1920*100vw;
  margin-bottom: 20/1920*100vw;
  color: #707b8b;
  @media (max-width: 1199px) {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
.congratulations-text{
  font-family: $font-bebas;
  letter-spacing: 1px;
  margin-bottom: 10/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
  h3{
    font-size: 24/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 18px;
    }
  }
  p{
    font-size: 18/1920*100vw;
    color: #9da3ac;
    @media (max-width: 1199px) {
      font-size: 16px;
    }
  }
}
.congratulations-cash{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30/1920*100vw;
  font: 69/1920*100vw $font-bebas;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
    font-size: 46px;
  }
  img{
    margin-left: 12/1920*100vw;
    width: 54/1920*100vw;
    @media (max-width: 1199px) {
      margin-left: 10px;
      width: 32px;
    }
  }
}
.btn-congratulations{
  @include btn(150/1920*100vw, 50/1920*100vw, 24/1920*100vw, $font-bebas);
  @media (max-width: 1199px) {
    @include btn(140px, 42px, 18px, $font-bebas);
  }
}