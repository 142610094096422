.block-feature{
  padding-bottom: 30/1920*100vw;
  @media (max-width: 1199px) {
    padding-bottom: 30px;
  }
}
.feature-game__slider{
  margin: 0 -35/1920*100vw;
  @media (max-width: 1199px) {
    margin: 0 -15px;
  }
}
.feature-game__col{
  padding: 25/1920*100vw 35/1920*100vw 0;
  position: relative;
  @media (max-width: 1199px) {
    padding: 12px 15px 0;
  }
}
.feature-game{
  &:hover{
    .feature-game__name a{
      color: $col-violet;
    }
    .feature-game__num{
      -webkit-text-fill-color: $col-violet;
      -webkit-text-stroke: 2px $col-violet;
    }
  }
}
.feature-game__num{
  position: absolute;
  top: 2px;
  left: 0;
  font: 180/1920*100vw $font-bebas;
  line-height: .8;
  -webkit-text-stroke: 2px #273545;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  transition: all .3s ease-in-out;
  background-image: repeating-linear-gradient(-45deg,
          transparent,
          transparent 3px,
          #1b2549 3px, #1b2549 5px);
  @media (max-width: 1199px) {
    font-size: 80px;
  }
}
.feature-game__img{
  height: 250/1920*100vw;
  overflow: hidden;
  border-radius: 50/1920*100vw;
  border: 7/1920*100vw solid #28416f;
  display: block;
  margin-bottom: 20/1920*100vw;
  position: relative;
  @media (max-width: 1199px) {
    height: 180px;
    border-radius: 30px;
    border-width: 4px;
    margin-bottom: 15px;
  }
  img{
    @include cover-style-img;
  }
}
.feature-game__name{
  font: 22/1920*100vw $font-bebas;
  text-align: center;
  @media (max-width: 1199px) {
    font-size: 18px;
  }
  a{
    transition: color .3s ease-in-out;
  }
}
.slider-arrow{
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 2;
  &__prev{
    left: 0;
    transform: translateY(-50%) rotate(180deg);
  }
  &__next{
    right: 0;
    transform: translateY(-50%);
  }
}