.register-text{
  margin-bottom: 20/1920*100vw;
  font: 30/1920*100vw $font-bebas;
  @media (max-width: 1199px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
}
.register-soc{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
  }
  a{
    margin: 0 8/1920*100vw 10/1920*100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130/1920*100vw;
    height: 65/1920*100vw;
    border-radius: 35/1920*100vw;
    transition: opacity .3s ease-in-out;
    padding: 10px 30px;
    @media (max-width: 1199px) {
      margin: 0 4px 10px;
      width: 90px;
      height: 46px;
      border-radius: 30px;
      padding: 0 20px;
    }
    &:hover{
      opacity: .6;
    }
    &.register-mail{
      background-image: linear-gradient(-45deg, #1e55ee, #19e3ff);
    }
    &.register-facebook{
      background-color: #3e59a2;
    }
    &.register-google{
      background-color: $col-white;
    }
  }
}
.register-log{
  font: 21/1920*100vw $font-bebas;
  margin-bottom: 10/1920*100vw;
  color: #9da3ac;
  letter-spacing: 1px;
  @media (max-width: 1199px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
}
.btn-login{
  @include btn(150/1920*100vw, 50/1920*100vw, 24/1920*100vw, $font-bebas);
  margin-bottom: 30/1920*100vw;
  @media (max-width: 1199px) {
    @include btn(120px, 42px, 18px, $font-bebas);
    margin-bottom: 10px;
  }
}
.popup-bottom__link{
  font-size: 14/1920*100vw;
  color: $col-grey;
  @media (max-width: 1199px) {
    font-size: 14px;
  }
  a{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}