.block-games{
  padding: 30/1920*100vw 0 125/1920*100vw;
  background: url("../images/bg/bg-game.png") center no-repeat;
  @media (max-width: 1199px) {
    padding: 30px 0 80px;
  }
  @media (max-width: 767px) {
    background: none;
    padding: 30px 0;
  }
  .row{
    margin: 0 -30/1920*100vw;
    @media (max-width: 1199px) {
      margin: 0 -15px;
    }
  }
  [class^="col-"]{
    padding: 0 30/1920*100vw;
    @media (max-width: 1199px) {
      padding: 0 15px;
    }
  }
}
.game{
  padding: 25/1920*100vw;
  background-color: #202b3a;
  border-radius: 25/1920*100vw;
  transition: all .3s ease-in-out;
  @media (max-width: 1199px) {
    padding: 25px;
    border-radius: 25px;
  }
  &:hover{
    box-shadow: 0 0 24px rgba(255, 255, 255, .4);
    .game-name a{
      color: $col-violet;
    }
  }
}
.game-img{
  border-radius: 20/1920*100vw;
  overflow: hidden;
  display: block;
  margin-bottom: 10/1920*100vw;
  height: 150/1920*100vw;
  box-shadow: 0 10/1920*100vw 25/1920*100vw rgba(16, 14, 20, .45);
  @media (max-width: 1199px) {
    border-radius: 20px;
    margin-bottom: 10px;
    height: 150px;
    box-shadow: 0 10px 25px rgba(16, 14, 20, .45);
  }
  img{
    @include cover-style-img
  }
}
.game-name{
  text-align: center;
  font: 20/1920*100vw $font-bebas;
  @media (max-width: 1199px) {
    font-size: 20px;
  }
  a{
    transition: color .3s ease-in-out;
  }
}