.withdraw-history{
  text-align: left;
  margin-bottom: 40/1920*100vw;
  padding: 25/1920*100vw;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 10/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 10px;
  }
  table{
    width: 100%;
  }
  th{
    font-family: $font-avenirDemi;
    color: #8d95a1;
    text-transform: uppercase;
    padding-bottom: 5/1920*100vw;
    @media (max-width: 1199px) {
      padding-bottom: 5px;
    }
  }
  td{
    font-size: 14/1920*100vw;
    color: #677281;
    padding: 5/1920*100vw 0;
    @media (max-width: 1199px) {
      padding: 5px 0;
      font-size: 14px;
    }
    &:first-child{
      width: 70%;
      @media (max-width: 567px) {
        width: 50%;
      }
    }
  }
}
.btn-history{
  @include btn(133/1920*100vw, 54/1920*100vw, 24/1920*100vw, $font-bebas);
  @media (max-width: 1199px) {
    @include btn(100px, 42px, 18px, $font-bebas);
  }
}