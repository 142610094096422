.block-advantage{
  padding: 98/1920*100vw 0 35/1920*100vw;
  background: url("../images/bg/bg-advantage.png") center bottom/cover;
  @media (max-width: 1199px) {
    padding: 40px 0;
  }
}
.advantage-row{
  display: flex;
  align-items: center;
}
.advantage-img{
  flex: 0 0 589/1920*100vw;
  width: 589/1920*100vw;
  margin: 0 80/1920*100vw 0 170/1920*100vw;
  @media (max-width: 1199px) {
    flex: 0 0 45%;
    width: 45%;
    margin: 30px 0 0 ;
  }
  @media (max-width: 567px) {
    display: none;
  }
}
.advantage-text{
  width: 545/1920*100vw;
  @media (max-width: 1199px) {
    width: auto;
  }
  h2{
    font: 56/1920*100vw $font-bebas;
    margin-bottom: 20/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 42px;
      margin-bottom: 15px;
    }
    span{
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 1px #949699;
    }
  }
  .btn{
    @include btn(280/1920*100vw, 70/1920*100vw, 32/1920*100vw, $font-bebas);
    @media (max-width: 1199px) {
      @include btn(180px, 46px, 24px, $font-bebas);
    }
  }
}