.withdraw-img{
  width: 47/1920*100vw;
  margin: 0 auto 13/1920*100vw;
  @media (max-width: 1199px) {
    width: 35px;
    margin-bottom: 10px;
  }
}
.withdraw-money{
  h2{
    font: 30/1920*100vw $font-bebas;
    color: $col-yellow;
    margin-bottom: 20/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 22px;
      margin-bottom: 15px;
    }
  }
  h3{
    font: 24/1920*100vw $font-bebas;
    margin-bottom: 10/1920*100vw;
    letter-spacing: 1.5px;
    @media (max-width: 1199px) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  .btn__gradient{
    @include btn(157/1920*100vw, 46/1920*100vw, 15/1920*100vw, $font-relewayMedium);
    margin-bottom: 13/1920*100vw;
    @media (max-width: 1199px) {
      @include btn(140px, 42px, 14px, $font-relewayMedium);
      margin-bottom: 8px;
    }
  }
  .btn__gray{
    @include btn(230/1920*100vw, 46/1920*100vw, 15/1920*100vw, $font-relewayMedium);
    margin-bottom: 13/1920*100vw;
    @media (max-width: 1199px) {
      @include btn(180px, 42px, 14px, $font-relewayMedium);
      margin-bottom: 8px;
    }
  }
}

.withdraw-pay{
  display: flex;
  justify-content: space-between;
}
.withdraw-balance{
  width: 175/1920*100vw;
  height: 100/1920*100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15/1920*100vw;
  border: 5/1920*100vw solid #324a74;
  background-color: rgba(255, 255, 255, .05);
  margin: 0 auto 30/1920*100vw;
  @media (max-width: 1199px) {
    width: 175px;
    height: 100px;
    border-radius: 15px;
    border-width: 5px;
    margin: 0 auto 15px;
  }
  h4{
    font: 15/1920*100vw $font-relewayMedium;
    margin-bottom: 8/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 15px;
      margin-bottom: 8px;
    }
  }
  p{
    display: flex;
    align-items: center;
    font: 30/1920*100vw $font-bebas;
    color: $col-green;
    @media (max-width: 1199px) {
      font-size: 26px;
    }
    img{
      margin-left: 10/1920*100vw;
      width: 34/1920*100vw;
      @media (max-width: 1199px) {
        margin-left: 8px;
        width: 30px;
      }
    }
  }
}