.burger{
  @media (max-width: 991px) {
    width: 26px;
    height: 22px;
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background: $col-white;
      transition: all .3s ease-in-out;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    span {
      position: absolute;
      width: 100%;
      height: 3px;
      background: $col-white;
      top: 50%;
      transform: translateY(-50%);
    }
    &.active {
      z-index: 1000;
      span {
        display: none;
      }
      &:before {
        top: 9.5px;
        transform: rotate(45deg);
      }
      &:after {
        bottom: 9.5px;
        transform: rotate(-45deg);
      }
    }
  }
  @media (min-width: 992px) {
    display: none;
  }
}
header{
  background-color: #202936;
  padding: 15/1920*100vw 75/1920*100vw;
  @media (max-width: 1199px){
    padding: 15px;
  }
}
.header-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-logo{
  width: 75/1920*100vw;
  @media (max-width: 1199px){
    width: 75px;
  }
}
.header-nav{
  display: flex;
  align-items: center;
  @media (min-width: 992px) {
    flex: 1 0 auto;
    padding-left: 50/1920*100vw;
    @media (max-width: 1199px){
      padding-left: 20px;
    }
  }
  @media (max-width: 991px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #202936;
    flex-direction: column;
    padding-top: 80px;
    transition: all .3s ease-in-out;
    transform: translateY(-100%);
    opacity: 0;
    z-index: 100;
    &.active{
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.header-menu{
  display: flex;
  @media (min-width: 991px) {
    flex: 1 0 auto;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
  li {
    @media (min-width: 992px) {
      margin-right: 70/1920*100vw;
      @media (max-width: 1199px) {
        margin-right: 25px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 991px){
      margin-bottom: 10px;
    }
  }
  a{
    color: $col-white;
    font: 22/1920*100vw $font-bebas;
    position: relative;
    transition: color .3s ease-in-out;
    @media (max-width: 1199px){
      font-size: 18px;
    }
    &:before{
      content: '';
      position: absolute;
      bottom: -12/1920*100vw;
      left: 50%;
      width: 0;
      height: 3/1920*100vw;
      background-color: $col-blue;
      transition: all .3s ease-in-out;
      opacity: 0;
      @media (max-width: 1199px){
        bottom: -10px;
        height: 3px;
      }
    }
    &:hover{
      color: $col-blue;
      &:before{
        width: 100%;
        left: 0;
        opacity: 1;
      }
    }
  }
}
.header-btn{
  @media (max-width: 567px){
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  a{
    margin: 0 10/1920*100vw;
    @include btn(164/1920*100vw, 47/1920*100vw, 16/1920*100vw, $font-avenir);
    @media (max-width: 1199px){
      margin: 0 3px;
      min-width: 140px;
      @include btn(140px, 40px, 14px, $font-avenir);
    }
    @media (max-width: 991px){
      margin: 0 3px 5px;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}
.header-account{
  display: flex;
  align-items: center;
  padding: 4/1920*100vw 4/1920*100vw 4/1920*100vw 20/1920*100vw;
  background-color: #131b26;
  border-radius: 40/1920*100vw;
  margin-left: 30/1920*100vw;
  @media (max-width: 1199px) {
    padding: 4px 4px 4px 10px;
    border-radius: 35px;
    margin-left: 15px;
  }
  @media (max-width: 991px) {
    margin: 10px 0 0;
  }
}
.account-money{
  align-items: center;
  display: flex;
  font: 18/1920*100vw $font-avenirDemi;
  margin-right: 20/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 16px;
    margin-right: 10px;
  }
  &:last-of-type img{
    margin-top: -20/1920*100vw;
    @media (max-width: 1199px) {
      margin-top: -10px;
    }
  }
  img{
    margin-right: 5/1920*100vw;
    width: 47/1920*100vw;
    @media (max-width: 1199px) {
      margin-right: 5px;
      width: 35px;
    }
  }
}
.header-user{
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 60/1920*100vw;
  height: 60/1920*100vw;
  width: 60/1920*100vw;
  border: 2px solid #475979;
  @media (max-width: 1199px) {
    flex: 0 0 40px;
    height: 40px;
    width: 40px;
  }
  img{
    @include cover-style-img;
  }
}