.popup-account__nickaname{
  margin-bottom: 23/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
  }
  p{
    font-size: 12/1920*100vw;
    color: #9da3ac;
    @media (max-width: 1199px) {
      font-size: 12px;
    }
  }
}
.change-nickname{
  position: relative;
  input{
    width: 100%;
    display: block;
    font: 30/1920*100vw $font-bebas;
    background: transparent;
    text-align: center;
    color: $col-violet;
    letter-spacing: 1px;
    @media (max-width: 1199px) {
      font-size: 22px;
    }
  }
}
.btn-change__nickname{
  position: absolute;
  cursor: pointer;
  top: -20/1920*100vw;
  right: 30%;
  width: 28/1920*100vw;
  height: 28/1920*100vw;
  border-radius: 50%;
  font-size: 14/1920*100vw;
  background-color: rgba(255, 255, 255, .05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #90969f;
  transition: all .3s ease-in-out;
  @media (max-width: 1199px) {
    top: -18px;
    width: 22px;
    height: 22px;
    font-size: 14px;
  }
  &:hover{
    background-color: $col-violet;
    color: $col-white;
  }
}
.title-balance{
  font: 24/1920*100vw $font-bebas;
  letter-spacing: 1.5px;
  margin-bottom: 8/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
}
.wrap-balance{
  display: flex;
  justify-content: space-between;
  width: 380px;
  margin: 0 auto 35/1920*100vw;
  @media (max-width: 1199px) {
    margin: 0 auto 15px;
  }
  @media (max-width: 567px) {
    width: 100%;
    flex-wrap: wrap;
  }
}
.box-balance-col{
  width: 175/1920*100vw;
  @media (max-width: 1199px) {
    width: 45%;
  }
  @media (max-width: 567px) {
    width: 100%;
  }
}
.box-balance{
  height: 100/1920*100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 15/1920*100vw;
  border: 5/1920*100vw solid #324a74;
  background-color: rgba(255, 255, 255, .05);
  @media (max-width: 1199px) {
    height: 100px;
    border-radius: 15px;
    border-width: 5px;
  }
  @media (max-width: 567px) {
    margin-bottom: 10px;
  }
  img{
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }
  h4{
    font: 15/1920*100vw $font-relewayMedium;
    @media (max-width: 1199px) {
      font-size: 15px;
    }
  }
  p{
    display: flex;
    align-items: center;
    font: 30/1920*100vw $font-bebas;
    @media (max-width: 1199px) {
      font-size: 26px;
    }
    i{
      width: 18/1920*100vw;
      height: 18/1920*100vw;
      color: $col-white;
      font-size: 10/1920*100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: 5/1920*100vw;
      @media (max-width: 1199px) {
        width: 18px;
        height: 18px;
        font-size: 10px;
        margin-left: 5px;
      }
    }
  }
  &__coin{
    p{
      color: $col-yellow;
      i{
        background-color: $col-yellow;
      }
    }
    img{
      left: -5/1920*100vw;
      width: 25/1920*100vw;
      @media (max-width: 1199px) {
        left: -5px;
        width: 25px;
      }
    }
  }
  &__money{
    img{
      right: -10/1920*100vw;
      width: 34/1920*100vw;
      @media (max-width: 1199px) {
        right: -10px;
        width: 34px;
      }
    }
    p{
      color: $col-green;
      i{
        background-color: $col-green;
      }
    }
  }
}
.popup-account__buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
  a{
    margin-bottom: 15/1920*100vw;
    @include btn(157/1920*100vw, 44/1920*100vw, 15/1920*100vw, $font-relewayMedium);
    @media (max-width: 1199px) {
      margin-bottom: 8px;
      @include btn(140px, 42px, 14px, $font-relewayMedium);
    }
  }
}
.popup-account__links{
  display: flex;
  justify-content: space-between;
  margin: 0 auto 30/1920*100vw;
  width: 380/1920*100vw;
  @media (max-width: 1199px) {
    margin: 0 auto 15px;
    width: 380px;
  }
  @media (max-width: 567px) {
    width: 100%;
    flex-direction: column;
  }
  a{
    text-decoration: underline;
    font-size: 14/1920*100vw;
    color: #677281;
    @media (max-width: 1199px) {
      font-size: 14px;
    }
    @media (max-width: 567px) {
      margin-bottom: 8px;
    }
    &:hover{
      text-decoration: none;
    }
  }
}
.link-bottom{
  a{
    font: 24/1920*100vw $font-bebas;
    text-decoration: underline;
    color: rgba(255, 255, 255, .2);
    @media (max-width: 1199px) {
      font-size: 20px;
    }
    &:hover{
      text-decoration: none;
    }
  }
}
.balance-note{
  color: $col-yellow;
  font: 11/1920*100vw $font-relewayMedium;
  margin-top: 5/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 11px;
    margin-top: 5px;
  }
}