.end-tournament{
  .tournament-scored{
    margin-bottom: 0;
  }
}
.tournament-win{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 15px;
  }
  img{
    width: 44/1920*100vw;
    margin-left: 9/1920*100vw;
    @media (max-width: 1199px) {
      width: 26px;
      margin-left: 8px;
    }
  }
}
.btn-collect{
  @include btn(170/1920*100vw, 55/1920*100vw, 24/1920*100vw, $font-bebas);
  @media (max-width: 1199px) {
    @include btn(140px, 42px, 18px, $font-bebas);
  }
}
.highscore{
  margin-top: 17/1920*100vw;
  font-size: 12/1920*100vw;
  color: #9da3ac;
  @media (max-width: 1199px) {
    margin-top: 10px;
    font-size: 12px;
  }
  span{
    color: $col-green;
  }
}
.lucky-coins__title{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10/1920*100vw;
  font: 50/1920*100vw $font-bebas;
  @media (max-width: 1199px) {
    font-size: 32px;
    margin-bottom: 10px;
  }
  img{
    width: 35/1920*100vw;
    margin-left: 10/1920*100vw;
    @media (max-width: 1199px) {
      width: 24px;
      margin-left: 8px;
    }
  }
}