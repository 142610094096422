//fonts
$font-avenirDemi: 'Avenir Next Cyr Demi';
$font-avenirMedium: 'Avenir Next Cyr Medium';
$font-avenir: 'Avenir Next Cyr';
$font-relewaySemiBold: 'Raleway SemiBold';
$font-relewayMedium: 'Raleway Medium';
$font-bebas: 'Bebas Neue';

//color
$col-white: #fff;
$col-blue: #2280ff;
$col-green: #3cdd4a;
$col-grey: #7a8595;
$col-violet: #ff26ef;
$col-yellow: #f0b500;