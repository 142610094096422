.cash-prizes{
  .popup-title{
    text-align: center;
    span{
      color: $col-green;
    }
  }
  .table-menu{
    margin-bottom: 10/1920*100vw;
    justify-content: center;
    @media (max-width: 1199px) {
      margin-bottom: 10px;
    }
    li{
      font-size: 21/1920*100vw;
      width: 30%;
      @media (max-width: 1199px) {
        font-size: 18px;
      }
      @media (max-width: 767px) {
        width: 50%;
      }
    }
    span{
      padding-bottom: 10/1920*100vw;
      @media (max-width: 1199px) {
        padding-bottom: 10px;
      }
      &:before{
        height: 3/1920*100vw;
        @media (max-width: 1199px) {
          height: 3px;
        }
      }
    }
  }
}
.cash-prizes__row{
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.cash-prizes__l{
  flex: 0 0 242/1920*100vw;
  @media (max-width: 1199px) {
    flex: 0 0 242px;
  }
  @media (max-width: 767px) {
    flex: 0 0 auto;
    width: 242px;
  }
}
.cash-prizes__r{
  padding-left: 20/1920*100vw;
  @media (max-width: 1199px) {
    padding-left: 20px;
  }
  @media (max-width: 767px) {
    padding-left: 0;
    margin-bottom: 20px;
  }
}
.cash-prizes__text{
  margin-bottom: 10/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 10px;
  }
  h2{
    font: 30/1920*100vw $font-bebas;
    letter-spacing: 1.5px;
    @media (max-width: 1199px) {
      font-size: 24px;
    }
  }
  h3{
    font: 20/1920*100vw $font-bebas;
    color: #636973;
    margin-bottom: 5/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }
  ul{
    li{
      margin-bottom: 5/1920*100vw;
      color: #9da3ac;
      font-size: 14/1920*100vw;
      @media (max-width: 1199px) {
        margin-bottom: 5px;
        font-size: 14px;
      }
      &:before{
        content: '- ';
      }
    }
  }
}
.active-prize{
  display: flex;
  align-items: center;
}
.active-prize__text{
  margin-right: 18/1920*100vw;
  @media (max-width: 1199px) {
    margin-right: 10px;
  }
  h3{
    font: 14/1920*100vw $font-bebas;
    letter-spacing: 1.5px;
    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }
  p{
    font-size: 14/1920*100vw;
    color: #979da6;
    @media (max-width: 1199px) {
      font-size: 14px;
    }
    i{
      color: $col-violet;
    }
  }
}
.active-prize__bon{
  display: flex;
  align-items: center;
  font: 30/1920*100vw $font-bebas;
  color: $col-green;
  @media (max-width: 1199px) {
    font-size: 24px;
  }
  img{
    width: 35/1920*100vw;
    margin-left: 5/1920*100vw;
    @media (max-width: 1199px) {
      width: 24px;
      margin-left: 5px;
    }
  }
}
.cash-bon{
  display: flex;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.cash-prizes__box{
  flex: 0 0 260/1920*100vw;
  margin-bottom: 10/1920*100vw;
  @media (max-width: 1199px) {
    flex: 0 0 260px;
    margin-bottom: 10px;
  }
  @media (max-width: 767px) {
    flex: 0 0 auto;
  }
}
.cash-prizes__bonus{
  width: 140/1920*100vw;
  height: 83/1920*100vw;
  border-radius: 15/1920*100vw;
  border: 5/1920*100vw solid #324a74;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12/1920*100vw;
  margin: 0 auto;
  @media (max-width: 1199px) {
    width: 140px;
    height: 83px;
    border-radius: 15px;
    border-width: 5px;
    padding: 12px;
  }
  p{
    font: 30/1920*100vw $font-bebas;
    color: $col-green;
    display: flex;
    align-items: center;
    @media (max-width: 1199px) {
      font-size: 30px;
    }
    img{
      width: 35/1920*100vw;
      margin-left: 5/1920*100vw;
      @media (max-width: 1199px) {
        width: 35px;
        margin-left: 5px;
      }
    }
  }
  h5{
    font: 14/1920*100vw $font-relewayMedium;
    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }
}
.cash-places{
  display: flex;
  flex-wrap: wrap;
}
.place{
  width: 80/1920*100vw;
  margin: 0 15/1920*100vw 15/1920*100vw 0;
  @media (max-width: 1199px) {
    width: 80px;
    margin: 0 15px 15px 0;
  }
  p{
    font: 20/1920*100vw $font-bebas;
    color: $col-green;
    display: flex;
    align-items: center;
    @media (max-width: 1199px) {
      font-size: 20px;
    }
    img{
      margin-left: 5/1920*100vw;
      width: 25/1920*100vw;
      @media (max-width: 1199px) {
        margin-left: 5px;
        width: 25px;
      }
    }
  }
  h5{
    font-size: 14/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 14px;
    }
  }
}
.tournament-info{
  display: flex;
  justify-content: space-around;
  margin-top: 30/1920*100vw;
  text-align: center;
  @media (max-width: 1199px) {
    margin-top: 15px;
  }
}
.tournament-info__box{
  h3{
    font: 18/1920*100vw $font-bebas;
    letter-spacing: 1.5px;
    @media (max-width: 1199px) {
      font-size: 16px;
    }
  }
  p{
    display: flex;
    justify-content: center;
    align-items: center;
    font: 30/1920*100vw $font-bebas;
    color: $col-green;
    @media (max-width: 1199px) {
      font-size: 24px;
    }
    img{
      width: 32/1920*100vw;
      margin-left: 10/1920*100vw;
      @media (max-width: 1199px) {
        width: 24px;
        margin-left: 8px;
      }
    }
  }
}
.cash-prizes__bottom{
  margin-top: 25/1920*100vw;
  text-align: center;
  @media (max-width: 1199px) {
    margin-top: 15px;
  }
  .btn{
    @include btn(200/1920*100vw, 55/1920*100vw, 24/1920*100vw, $font-bebas);
    margin-bottom: 15/1920*100vw;
    @media (max-width: 1199px) {
      @include btn(180px, 42px, 18px, $font-bebas);
      margin-bottom: 10px;
    }
  }
}
.quick-tip{
  margin: 0 auto 30px;
  border-radius: 15/1920*100vw;
  max-width: 432/1920*100vw;
  padding: 25/1920*100vw 20/1920*100vw;
  background-color: rgba(0, 0, 0, .1);
  color: #677281;
  font-size: 14/1920*100vw;
  @media (max-width: 1199px) {
    border-radius: 15px;
    max-width: 432px;
    padding: 20px 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  h2{
    font-family: $font-avenirDemi;
    margin-bottom: 15/1920*100vw;
    color: #8d95a1;
    font-size: 16/1920*100vw;
    @media (max-width: 1199px) {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}