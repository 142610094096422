.block-table{
  padding: 80/1920*100vw 0;
  background: url("../images/bg/bg-pages.png") center top/100% no-repeat;
  @media (max-width: 1199px) {
    padding: 40px 0;
  }
}
.table-wrap{
  margin: 0 auto;
  &__w990{
    width: 990/1920*100vw;
  }
  &__w830{
    width: 830/1920*100vw;
  }
  @media (max-width: 1199px) {
    width: 100%;
    padding: 0 15px;
  }
}
.table-menu{
  display: flex;
  margin-bottom: 52/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 30px;
  }
  li{
    width: 50%;
    text-align: center;
    cursor: pointer;
    font: 40/1920*100vw $font-bebas;
    color: rgba(157, 163, 172, .5);
    transition: color .3s ease-in-out;
    @media (max-width: 1199px) {
      font-size: 26px;
    }
    &:first-child{
      span:before{
        background-color: $col-violet;
      }
    }
    &:last-child{
      span:before{
        background-color: $col-green;
      }
    }
    &.active, &:hover{
      color: #9da3ac;
      span:before{
        width: 100%;
        left: 0;
      }
    }
  }
  span{
    padding-bottom: 20/1920*100vw;
    position: relative;
    display: inline-block;
    @media (max-width: 1199px) {
      padding-bottom: 8px;
    }
    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 5/1920*100vw;
      transition: all .3s ease-in-out;
      @media (max-width: 1199px) {
        height: 3px;
      }
    }
  }
}
.winner-table{
  width: 100%;
}
.t-head{
  display: flex;
  font: 30/1920*100vw $font-bebas;
  margin-bottom: 20/1920*100vw;
  @media (max-width: 1199px) {
    font-size: 18px;
  }
}
.t-row{
  display: flex;
  align-items: center;
  font: 20/1920*100vw $font-bebas;
  color: rgba(255, 255, 255, .5);
  padding: 15/1920*100vw 0;
  border-bottom: 1px solid rgba(255, 255, 255, .5);
  transition: background-color .3s ease-in-out;
  @media (max-width: 1199px) {
    font-size: 16px;
    padding: 10px 0;
  }
  &:hover{
    background-color: rgba(255, 255, 255, .4);
    border-bottom-color: transparent;
    border-radius: 8/1920*100vw;
    @media (max-width: 1199px) {
      border-radius: 8px;
    }
  }
  &.my-position{
    background-color: rgba(255, 255, 255, .15);
    font-size: 24/1920*100vw;
    color: $col-white;
    &:hover{
      background-color: rgba(255, 255, 255, .4);
    }
    @media (max-width: 1199px) {
      font-size: 16px;
    }
    .table-user img{
      flex: 0 0 62/1920*100vw;
      height: 62/1920*100vw;
      width: 62/1920*100vw;
      @media (max-width: 1199px) {
        flex: 0 0 32px;
        height: 32px;
        width: 32px;
      }
    }
  }
}
.t-contest{
  width: 45.5%;
  padding: 0 10/1920*100vw 0 25/1920*100vw;
  @media (max-width: 1199px) {
    padding: 0 10px;
  }
  @media (max-width: 567px) {
    width: 33.5%;
  }
}
.t-date{
  width: 33.5%;
}
.t-cash{
  width: 21%;
  text-align: center;
  @media (max-width: 567px) {
    width: 33%;
  }
}
.t-user{
  width: 45.5%;
  padding: 0 10/1920*100vw 0 25/1920*100vw;
  @media (max-width: 1199px) {
    padding: 0 10px;
  }
  @media (max-width: 567px) {
    width: 48%;
  }
}
.t-prize{
  width: 33.5%;
  @media (max-width: 567px) {
    width: 19%;
  }
}
.t-game{
  display: block;
  width: 46/1920*100vw;
  height: 46/1920*100vw;
  border-radius: 10/1920*100vw;
  overflow: hidden;
  box-shadow: 0 10/1920*100vw 35/1920*100vw rgba(16, 14, 20, .45);
  @media (max-width: 1199px) {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    box-shadow: 0 8px 22px rgba(16, 14, 20, .45);
  }
}
.table-user{
  display: inline-flex;
  align-items: center;
  img{
    flex: 0 0 46/1920*100vw;
    height: 46/1920*100vw;
    width: 46/1920*100vw;
    border-radius: 50%;
    margin-right: 20/1920*100vw;
    @media (max-width: 1199px) {
      flex: 0 0 32px;
      height: 32px;
      width: 32px;
      margin-right: 10px;
    }
  }
}
.my-cash{
  display: inline-flex;
  align-items: center;
  img{
    width: 34/1920*100vw;
    margin-left: 7/1920*100vw;
    @media (max-width: 1199px) {
      width: 28px;
      margin-left: 5px;
    }
  }
}
.table-info{
  text-align: center;
  font-family: $font-bebas;
  margin-bottom: 40/1920*100vw;
  @media (max-width: 1199px) {
    margin-bottom: 20px;
  }
  h2, h3{
    font-size: 40/1920*100vw;
    @media (max-width: 1199px) {
      font-size: 28px;
    }
  }
  h2 span{
    color: $col-yellow;
  }
  h3 span{
    color: $col-green;
  }
  p{
    font-size: 24/1920*100vw;
    color: #9da3ac;
    @media (max-width: 1199px) {
      font-size: 18px;
    }
  }
}
.table-game{
  display: block;
  width: 176/1920*100vw;
  height: 176/1920*100vw;
  margin: 0 auto 20/1920*100vw;
  border-radius: 50/1920*100vw;
  border: 7/1920*100vw solid #28416f;
  overflow: hidden;
  @media (max-width: 1199px) {
    width: 120px;
    height: 120px;
    margin: 0 auto 20px;
    border-radius: 30px;
    border-width: 4px;
  }
  img{
    @include cover-style-img;
  }
}