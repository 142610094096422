.popup-nickname{
  color: $col-violet;
  font: 30/1920*100vw $font-bebas;
  margin-bottom: 30/1920*100vw;
  text-align: center;
  @media (max-width: 1199px) {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
.title-results{
  font: 21/1920*100vw $font-bebas;
  margin-bottom: 15/1920*100vw;
  color: #9da3ac;
  letter-spacing: 1px;
  @media (max-width: 1199px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.box-highscores{
  display: flex;
  flex-wrap: wrap;
  &__scroll{
    max-height: 400px;
    overflow-y: auto;
    scrollbar-color: $col-violet #323c48;
    scrollbar-width: thin;
    &::-webkit-scrollbar{
      width: 3px;
    }
    &::-webkit-scrollbar-track  {
      background-color: #323c48;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $col-violet;
    }
  }
}
.game-result{
  display: flex;
  align-items: center;
  margin-bottom: 22/1920*100vw;
  padding-right: 15/1920*100vw;
  width: 50%;
  @media (max-width: 1199px) {
    margin-bottom: 12px;
    padding-right: 10px;
  }
  @media (max-width: 567px) {
    width: 100%;
    padding-right: 0;
  }
}
.game-result__img{
  flex: 0 0 63/1920*100vw;
  width: 63/1920*100vw;
  height: 63/1920*100vw;
  border-radius: 15/1920*100vw;
  overflow: hidden;
  box-shadow: 0 10/1920*100vw 35/1920*100vw rgba(16, 14, 20, .45);
  @media (max-width: 1199px) {
    flex: 0 0 46px;
    width: 46px;
    height: 46px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(16, 14, 20, .45);
  }
  img{
    @include cover-style-img;
  }
}
.game-result__info{
  font-family: $font-bebas;
  padding-left: 15/1920*100vw;
  letter-spacing: 1px;
  width: 80%;
  @media (max-width: 1199px) {
    padding-left: 10px;
  }
  h3{
    font-size: 18/1920*100vw;
    @include text-overflow;
    @media (max-width: 1199px) {
      font-size: 16px;
    }
  }
  p{
    color: $col-green;
  }
}