@media (min-width: 568px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 83.8541666667vw;
  }
}

.container {
  padding: 0 2.0833333333vw;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .container {
    padding: 0 15px;
  }
}

.row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -2.0833333333vw;
  margin-right: -2.0833333333vw;
}

@media (max-width: 1199px) {
  .row {
    margin: 0 -15px;
  }
}

[class^="col-"] {
  flex: 0 0 100%;
  padding-left: 2.0833333333vw;
  padding-right: 2.0833333333vw;
}

@media (max-width: 1199px) {
  [class^="col-"] {
    padding: 0 15px;
  }
}

@media (min-width: 568px) {
  .col-xs-1 {
    flex: 0 0 8.3333333333%;
  }
}

@media (min-width: 568px) {
  .col-xs-2 {
    flex: 0 0 16.6666666667%;
  }
}

@media (min-width: 568px) {
  .col-xs-3 {
    flex: 0 0 25%;
  }
}

@media (min-width: 568px) {
  .col-xs-4 {
    flex: 0 0 33.3333333333%;
  }
}

@media (min-width: 568px) {
  .col-xs-5 {
    flex: 0 0 41.6666666667%;
  }
}

@media (min-width: 568px) {
  .col-xs-6 {
    flex: 0 0 50%;
  }
}

@media (min-width: 568px) {
  .col-xs-7 {
    flex: 0 0 58.3333333333%;
  }
}

@media (min-width: 568px) {
  .col-xs-8 {
    flex: 0 0 66.6666666667%;
  }
}

@media (min-width: 568px) {
  .col-xs-9 {
    flex: 0 0 75%;
  }
}

@media (min-width: 568px) {
  .col-xs-10 {
    flex: 0 0 83.3333333333%;
  }
}

@media (min-width: 568px) {
  .col-xs-11 {
    flex: 0 0 91.6666666667%;
  }
}

@media (min-width: 568px) {
  .col-xs-12 {
    flex: 0 0 100%;
  }
}

@media (min-width: 768px) {
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-sm-3 {
    flex: 0 0 25%;
  }
}

@media (min-width: 768px) {
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-sm-6 {
    flex: 0 0 50%;
  }
}

@media (min-width: 768px) {
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-sm-9 {
    flex: 0 0 75%;
  }
}

@media (min-width: 768px) {
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-sm-12 {
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
  }
}

@media (min-width: 992px) {
  .col-md-2 {
    flex: 0 0 16.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-md-3 {
    flex: 0 0 25%;
  }
}

@media (min-width: 992px) {
  .col-md-4 {
    flex: 0 0 33.3333333333%;
  }
}

@media (min-width: 992px) {
  .col-md-5 {
    flex: 0 0 41.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-md-6 {
    flex: 0 0 50%;
  }
}

@media (min-width: 992px) {
  .col-md-7 {
    flex: 0 0 58.3333333333%;
  }
}

@media (min-width: 992px) {
  .col-md-8 {
    flex: 0 0 66.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-md-9 {
    flex: 0 0 75%;
  }
}

@media (min-width: 992px) {
  .col-md-10 {
    flex: 0 0 83.3333333333%;
  }
}

@media (min-width: 992px) {
  .col-md-11 {
    flex: 0 0 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-md-12 {
    flex: 0 0 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
  }
}

@media (min-width: 1200px) {
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg-3 {
    flex: 0 0 25%;
  }
}

@media (min-width: 1200px) {
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
  }
}

@media (min-width: 1200px) {
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg-6 {
    flex: 0 0 50%;
  }
}

@media (min-width: 1200px) {
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
  }
}

@media (min-width: 1200px) {
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg-9 {
    flex: 0 0 75%;
  }
}

@media (min-width: 1200px) {
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
  }
}

@media (min-width: 1200px) {
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-lg-12 {
    flex: 0 0 100%;
  }
}

@font-face {
  font-family: 'Bebas Neue';
  src: url("../fonts/BebasNeue-Regular.eot");
  src: url("../fonts/BebasNeue-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BebasNeue-Regular.woff2") format("woff2"), url("../fonts/BebasNeue-Regular.woff") format("woff"), url("../fonts/BebasNeue-Regular.ttf") format("truetype"), url("../fonts/BebasNeue-Regular.svg#BebasNeue-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-Regular.eot");
  src: url("../fonts/AvenirNextCyr-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirNextCyr-Regular.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Regular.woff") format("woff"), url("../fonts/AvenirNextCyr-Regular.ttf") format("truetype"), url("../fonts/AvenirNextCyr-Regular.svg#AvenirNextCyr-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway Medium';
  src: url("../fonts/Raleway-Medium.eot");
  src: url("../fonts/Raleway-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Raleway-Medium.woff2") format("woff2"), url("../fonts/Raleway-Medium.woff") format("woff"), url("../fonts/Raleway-Medium.ttf") format("truetype"), url("../fonts/Raleway-Medium.svg#Raleway-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway SemiBold';
  src: url("../fonts/Raleway-SemiBold.eot");
  src: url("../fonts/Raleway-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Raleway-SemiBold.woff2") format("woff2"), url("../fonts/Raleway-SemiBold.woff") format("woff"), url("../fonts/Raleway-SemiBold.ttf") format("truetype"), url("../fonts/Raleway-SemiBold.svg#Raleway-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Medium';
  src: url("../fonts/AvenirNextCyr-Medium.eot");
  src: url("../fonts/AvenirNextCyr-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirNextCyr-Medium.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Medium.woff") format("woff"), url("../fonts/AvenirNextCyr-Medium.ttf") format("truetype"), url("../fonts/AvenirNextCyr-Medium.svg#AvenirNextCyr-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Demi';
  src: url("../fonts/AvenirNextCyr-Demi.eot");
  src: url("../fonts/AvenirNextCyr-Demi.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirNextCyr-Demi.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Demi.woff") format("woff"), url("../fonts/AvenirNextCyr-Demi.ttf") format("truetype"), url("../fonts/AvenirNextCyr-Demi.svg#AvenirNextCyr-Demi") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?i60y4o");
  src: url("../fonts/icomoon.eot?i60y4o#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?i60y4o") format("truetype"), url("../fonts/icomoon.woff?i60y4o") format("woff"), url("../fonts/icomoon.svg?i60y4o#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon_2.eot?rj4ibu");
  src: url("../fonts/icomoon_2.eot?rj4ibu#iefix") format("embedded-opentype"), url("../fonts/icomoon_2.ttf?rj4ibu") format("truetype"), url("../fonts/icomoon_2.woff?rj4ibu") format("woff"), url("../fonts/icomoon_2.svg?rj4ibu#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-checkmark:before {
  content: "\e906";
}

.icon-user:before {
  content: "\e916";
}

.icon-arrow-left:before {
  content: "\e907";
}

.icon-arrow-right:before {
  content: "\e908";
}

.icon-plus:before {
  content: "\e909";
}

.icon-pause:before {
  content: "\e90a";
}

.icon-volume:before {
  content: "\e90b";
}

.icon-linkedin:before {
  content: "\e90c";
}

.icon-youtube:before {
  content: "\e90d";
}

.icon-apple:before {
  content: "\e90e";
}

.icon-instagram:before {
  content: "\e905";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-twitter:before {
  content: "\e903";
}

.icon-googleplay:before {
  content: "\e90f";
  color: #607d8b;
}

.icon-bell-o:before {
  content: "\e910";
}

.icon-clear:before {
  content: "\e911";
}

.icon-timer:before {
  content: "\e901";
}

.icon-person_add_alt:before {
  content: "\e912";
}

.icon-video:before {
  content: "\e913";
}

.icon-play:before {
  content: "\e902";
}

.icon-mail-envelope-closed:before {
  content: "\e914";
}

.icon-pen-angled:before {
  content: "\e915";
}

.icon-lock:before {
  content: "\e900";
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input, select, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

input, select, textarea {
  outline: none;
  appearance: none;
}

.slick-slider * {
  outline: none;
}

body {
  line-height: 1;
  min-width: 320px;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
}

a:hover, a:focus {
  text-decoration: none;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

@keyframes top-shadow {
  from {
    box-shadow: 0 0 0 0 #192d51;
  }
  to {
    box-shadow: 0 0 0 1.71875vw rgba(25, 45, 81, 0.5);
    @media (max-width: 1199px) {
      box-shadow: 0 0 0 18px rgba(25, 45, 81, 0.5);
    }
  }
}

@keyframes line-effect {
  from {
    filter: brightness(0.8);
  }
  50% {
    filter: brightness(1.5);
  }
  to {
    filter: brightness(0.8);
  }
}

@keyframes light-line {
  from {
    background-position: -80px 0;
  }
  to {
    background-position: 280px 0;
  }
}

body {
  font: 0.8333333333vw "Avenir Next Cyr";
  color: #fff;
  overflow-x: hidden;
  background-color: #101922;
}

@media (max-width: 1199px) {
  body {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  body.active-menu {
    overflow: hidden;
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2604166667vw;
  padding: 0 0.5208333333vw;
  transition: all .3s ease-in-out;
  position: relative;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .btn {
    border-radius: 5px;
    padding: 0 10px;
  }
}

.btn__icon--r span {
  width: 100%;
  text-align: center;
}

.btn__icon i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.btn__blue {
  background-color: #2280ff;
  color: #fff;
  border: 2px solid #2280ff;
}

.btn__blue:hover {
  background-color: transparent;
  color: #2280ff;
}

.btn__trans {
  background-color: transparent;
  color: #2280ff;
  border: 2px solid #2280ff;
}

.btn__trans:hover {
  background-color: #2280ff;
  color: #fff;
}

.btn__gradient {
  background: linear-gradient(28deg, #e637fe 25%, #3092fe 50%, #e637fe 100%) 0 0/200%;
  color: #fff;
}

.btn__gradient:hover {
  background-position: 100% 0;
}

.btn__gray {
  background-color: #43576e;
  color: #fff;
  border: 2px solid #43576e;
}

.btn__gray:hover {
  background-color: transparent;
  color: #43576e;
}

.align-item-center {
  align-items: center;
}

.tab-content {
  display: none;
}

.tab-content:nth-of-type(1) {
  display: block;
}

.block-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5625vw;
}

@media (max-width: 1199px) {
  .block-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .block-title {
    flex-direction: column;
    text-align: center;
  }
}

.block-title__violet span {
  color: #ff26ef;
}

.block-title__violet span.line-animation {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-background-size: 120px;
  background-image: linear-gradient(-100deg, #ff26ef, rgba(255, 255, 255, 0.7) 3px, rgba(255, 255, 255, 0.7) 8px, #ff26ef 9px);
  animation: light-line 6s linear infinite;
}

.block-title__green span {
  color: #3cdd4a;
}

.block-title__r {
  text-align: right;
}

@media (max-width: 767px) {
  .block-title__r {
    text-align: center;
  }
}

.block-title h2 {
  font: 2.6041666667vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .block-title h2 {
    font-size: 36px;
  }
}

.block-title h3 {
  font: 1.875vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .block-title h3 {
    font-size: 26px;
  }
}

.block-title p {
  font: 1.09375vw "Bebas Neue";
  color: #9da3ac;
}

@media (max-width: 1199px) {
  .block-title p {
    font-size: 18px;
  }
}

.block-title i {
  color: #f0b500;
}

.block-title__r--top {
  display: inline-flex;
  align-items: center;
}

.block-title__r--top .coin {
  width: 3.4375vw;
}

@media (max-width: 1199px) {
  .block-title__r--top .coin {
    width: 42px;
  }
}

.block-title__r--top .money {
  width: 3.28125vw;
  margin: -2.1875vw 0 0 0.4166666667vw;
}

@media (max-width: 1199px) {
  .block-title__r--top .money {
    width: 40px;
    margin: -20px 0 0 8px;
  }
}

.mb60 {
  margin-bottom: 3.125vw;
}

@media (max-width: 1199px) {
  .mb60 {
    margin-bottom: 30px;
  }
}

.mb50 {
  margin-bottom: 2.6041666667vw;
}

@media (max-width: 1199px) {
  .mb50 {
    margin-bottom: 20px;
  }
}

.mt20 {
  margin-top: 1.1458333333vw;
}

@media (max-width: 1199px) {
  .mt20 {
    margin-top: 10px;
  }
}

.color-green {
  color: #3cdd4a;
}

.list-popups {
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: decimal;
}

.list-popups li {
  margin-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.line-effect {
  position: relative;
}

.line-effect img {
  animation: 3s linear line-effect infinite;
}

.shake-anim {
  animation: 3s linear tada infinite;
}

@media (max-width: 991px) {
  .burger {
    width: 26px;
    height: 22px;
    position: relative;
  }
  .burger:before, .burger:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: #fff;
    transition: all .3s ease-in-out;
  }
  .burger:before {
    top: 0;
  }
  .burger:after {
    bottom: 0;
  }
  .burger span {
    position: absolute;
    width: 100%;
    height: 3px;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
  }
  .burger.active {
    z-index: 1000;
  }
  .burger.active span {
    display: none;
  }
  .burger.active:before {
    top: 9.5px;
    transform: rotate(45deg);
  }
  .burger.active:after {
    bottom: 9.5px;
    transform: rotate(-45deg);
  }
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

header {
  background-color: #202936;
  padding: 0.78125vw 3.90625vw;
}

@media (max-width: 1199px) {
  header {
    padding: 15px;
  }
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo {
  width: 3.90625vw;
}

@media (max-width: 1199px) {
  .header-logo {
    width: 75px;
  }
}

.header-nav {
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .header-nav {
    flex: 1 0 auto;
    padding-left: 2.6041666667vw;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-nav {
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .header-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #202936;
    flex-direction: column;
    padding-top: 80px;
    transition: all .3s ease-in-out;
    transform: translateY(-100%);
    opacity: 0;
    z-index: 100;
  }
  .header-nav.active {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-menu {
  display: flex;
}

@media (min-width: 991px) {
  .header-menu {
    flex: 1 0 auto;
  }
}

@media (max-width: 991px) {
  .header-menu {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .header-menu li {
    margin-right: 3.6458333333vw;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-menu li {
    margin-right: 25px;
  }
}

@media (min-width: 992px) {
  .header-menu li:last-child {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .header-menu li {
    margin-bottom: 10px;
  }
}

.header-menu a {
  color: #fff;
  font: 1.1458333333vw "Bebas Neue";
  position: relative;
  transition: color .3s ease-in-out;
}

@media (max-width: 1199px) {
  .header-menu a {
    font-size: 18px;
  }
}

.header-menu a:before {
  content: '';
  position: absolute;
  bottom: -0.625vw;
  left: 50%;
  width: 0;
  height: 0.15625vw;
  background-color: #2280ff;
  transition: all .3s ease-in-out;
  opacity: 0;
}

@media (max-width: 1199px) {
  .header-menu a:before {
    bottom: -10px;
    height: 3px;
  }
}

.header-menu a:hover {
  color: #2280ff;
}

.header-menu a:hover:before {
  width: 100%;
  left: 0;
  opacity: 1;
}

@media (max-width: 567px) {
  .header-btn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.header-btn a {
  margin: 0 0.5208333333vw;
  width: 8.5416666667vw;
  height: 2.4479166667vw;
  font: 0.8333333333vw "Avenir Next Cyr";
}

@media (max-width: 1199px) {
  .header-btn a {
    margin: 0 3px;
    min-width: 140px;
    width: 140px;
    height: 40px;
    font: 14px "Avenir Next Cyr";
  }
}

@media (max-width: 991px) {
  .header-btn a {
    margin: 0 3px 5px;
  }
}

.header-btn a:last-child {
  margin-right: 0;
}

.header-account {
  display: flex;
  align-items: center;
  padding: 0.2083333333vw 0.2083333333vw 0.2083333333vw 1.0416666667vw;
  background-color: #131b26;
  border-radius: 2.0833333333vw;
  margin-left: 1.5625vw;
}

@media (max-width: 1199px) {
  .header-account {
    padding: 4px 4px 4px 10px;
    border-radius: 35px;
    margin-left: 15px;
  }
}

@media (max-width: 991px) {
  .header-account {
    margin: 10px 0 0;
  }
}

.account-money {
  align-items: center;
  display: flex;
  font: 0.9375vw "Avenir Next Cyr Demi";
  margin-right: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .account-money {
    font-size: 16px;
    margin-right: 10px;
  }
}

.account-money:last-of-type img {
  margin-top: -1.0416666667vw;
}

@media (max-width: 1199px) {
  .account-money:last-of-type img {
    margin-top: -10px;
  }
}

.account-money img {
  margin-right: 0.2604166667vw;
  width: 2.4479166667vw;
}

@media (max-width: 1199px) {
  .account-money img {
    margin-right: 5px;
    width: 35px;
  }
}

.header-user {
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 3.125vw;
  height: 3.125vw;
  width: 3.125vw;
  border: 2px solid #475979;
}

@media (max-width: 1199px) {
  .header-user {
    flex: 0 0 40px;
    height: 40px;
    width: 40px;
  }
}

.header-user img {
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.block-banner {
  padding: 0.78125vw 0 5.4166666667vw;
  background: url("../images/bg/bg-banner.png") top center/cover no-repeat;
}

@media (max-width: 1199px) {
  .block-banner {
    padding: 15px 0 80px;
  }
}

.tab-content__banner:nth-of-type(1) .banner-text span {
  color: #ff26ef;
}

.tab-content__banner:nth-of-type(1) .banner-text h2 i {
  -webkit-text-stroke-color: #ff26ef;
}

.tab-content__banner:nth-of-type(2) .banner-text span {
  color: #3cdd4a;
}

.tab-content__banner:nth-of-type(2) .banner-text h2 i {
  -webkit-text-stroke-color: #3cdd4a;
}

.banner-l {
  margin-bottom: 6.25vw;
}

@media (max-width: 1199px) {
  .banner-l {
    margin-bottom: 0;
  }
}

@media (max-width: 1199px) {
  .banner-text {
    text-align: center;
  }
}

.banner-text h3 {
  text-transform: uppercase;
  font: 4.5833333333vw "Bebas Neue";
  color: #495a71;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.34);
  -webkit-text-fill-color: transparent;
}

@media (max-width: 1199px) {
  .banner-text h3 {
    font-size: 46px;
  }
}

.banner-text h2 {
  font: 5vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .banner-text h2 {
    font-size: 54px;
  }
}

.banner-text h2 i {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
}

.banner-time {
  display: flex;
  align-items: center;
}

@media (max-width: 1199px) {
  .banner-time {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .banner-time {
    flex-direction: column;
  }
}

.banner-time h4 {
  text-transform: uppercase;
  font: 2.5vw "Bebas Neue";
  margin-right: 0.625vw;
}

@media (max-width: 1199px) {
  .banner-time h4 {
    font-size: 32px;
    margin: 0 10px 10px 0;
  }
}

.timer {
  display: flex;
}

.timer-box {
  margin: 0 0.2083333333vw 0.4166666667vw;
}

@media (max-width: 1199px) {
  .timer-box {
    margin: 0 2px 8px;
  }
}

.timer-box p {
  text-align: center;
  color: #7a8595;
  font-size: 0.8854166667vw;
}

@media (max-width: 1199px) {
  .timer-box p {
    font-size: 15px;
  }
}

.timer-num {
  display: flex;
}

.timer-num span {
  margin: 0 0.1302083333vw;
  height: 4.21875vw;
  width: 3.0729166667vw;
  background: url("../images/other/timer-bg.png") center/contain no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 2.6041666667vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .timer-num span {
    margin: 0 2px;
    height: 50px;
    width: 38px;
    font-size: 32px;
  }
}

.banner-winners {
  display: flex;
}

@media (max-width: 1199px) {
  .banner-winners {
    justify-content: center;
    padding-right: 70px;
  }
}

@media (max-width: 767px) {
  .banner-winners {
    flex-direction: column;
    padding-right: 0;
  }
}

.banner-winners__r {
  position: relative;
  width: 100%;
}

@media (max-width: 1199px) {
  .banner-winners__r {
    width: auto;
  }
}

@media (max-width: 767px) {
  .banner-winners__r {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
}

.winner-img {
  width: 33.4895833333vw;
  flex: 0 0 33.4895833333vw;
  display: none;
  position: relative;
}

@media (max-width: 1199px) {
  .winner-img {
    width: 440px;
    flex: 0 0 440px;
  }
}

@media (max-width: 767px) {
  .winner-img {
    flex: 0 0 auto;
    width: 100%;
  }
}

.winner-img:nth-of-type(1) {
  display: block;
}

.winner-img__1 .parallax-icon:nth-of-type(1) {
  top: 0.46875vw;
  right: 8.3854166667vw;
  width: 5vw;
}

.winner-img__1 .parallax-icon:nth-of-type(2) {
  top: 12.9166666667vw;
  right: 2.96875vw;
  width: 3.75vw;
}

.winner-img__1 .parallax-icon:nth-of-type(3) {
  top: 9.21875vw;
  right: 6.3020833333vw;
  width: 3.2291666667vw;
}

.winner-img__1 .parallax-icon:nth-of-type(4) {
  bottom: 240px;
  right: 100px;
  width: 3.2291666667vw;
}

.winner-img__1 .parallax-icon:nth-of-type(5) {
  top: 41px;
  left: 95px;
  width: 5.1041666667vw;
}

.winner-img__1 .parallax-icon:nth-of-type(6) {
  bottom: -0.625vw;
  left: 0.8333333333vw;
  width: 23.9583333333vw;
  z-index: 2;
}

@media (max-width: 1199px) {
  .winner-img__1 .parallax-icon:nth-of-type(6) {
    display: block;
    width: 65.2272727273%;
    bottom: -1.3636363636%;
    left: 6.5909090909%;
  }
}

.winner-img__2 .parallax-icon:nth-of-type(1) {
  bottom: 8.3333333333vw;
  right: 1.9791666667vw;
  width: 4.6875vw;
}

.winner-img__2 .parallax-icon:nth-of-type(2) {
  top: 9.3229166667vw;
  right: 0;
  width: 3.4895833333vw;
}

.winner-img__2 .parallax-icon:nth-of-type(3) {
  top: 0;
  right: 6.3020833333vw;
  width: 3.5416666667vw;
}

.winner-img__2 .parallax-icon:nth-of-type(4) {
  top: 1.0416666667vw;
  left: 7.8645833333vw;
  width: 3.125vw;
}

.winner-img__2 .parallax-icon:nth-of-type(5) {
  top: 7.03125vw;
  left: 1.40625vw;
  width: 3.1770833333vw;
}

.winner-img__2 .parallax-icon:nth-of-type(6) {
  bottom: 21px;
  left: 1.25vw;
  width: 26.6666666667vw;
  z-index: 2;
}

@media (max-width: 1199px) {
  .winner-img__2 .parallax-icon:nth-of-type(6) {
    display: block;
    bottom: 3.6363636364%;
    left: 5.6818181818%;
    width: 76.3636363636%;
  }
}

.parallax-icon {
  position: absolute;
}

@media (max-width: 1199px) {
  .parallax-icon {
    display: none;
  }
}

.top-users {
  counter-reset: position;
}

@media (max-width: 767px) {
  .top-users {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.winner-user {
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
}

@media (max-width: 767px) {
  .winner-user {
    position: static;
    margin: 0 10px 30px;
  }
}

.winner-user:nth-child(1) {
  top: 13.5%;
  left: -0.2604166667vw;
}

@media (max-width: 1199px) {
  .winner-user:nth-child(1) {
    top: 8%;
    left: -15px;
  }
}

@media (min-width: 1200px) {
  .winner-user:nth-child(1) .user-photo {
    flex: 0 0 3.75vw;
    height: 3.75vw;
  }
}

.winner-user:nth-child(1) .user-photo:before {
  background-color: #f6aa20;
}

@media (min-width: 1200px) {
  .winner-user:nth-child(1) .user-photo:before {
    width: 0.8854166667vw;
    height: 1.1979166667vw;
    font-size: 0.6979166667vw;
  }
}

.winner-user:nth-child(1) .user-photo:after {
  content: '';
  position: absolute;
  bottom: 105%;
  left: 50%;
  transform: translateX(-50%);
  width: 1.9791666667vw;
  height: 1.9791666667vw;
  background: url("../images/icons/top-icon.png") center/contain no-repeat;
}

@media (max-width: 1199px) {
  .winner-user:nth-child(1) .user-photo:after {
    width: 26px;
    height: 26px;
  }
}

.winner-user:nth-child(1) .user-photo img {
  animation: 2s ease-in-out 1s top-shadow infinite;
}

.winner-user:nth-child(2) {
  top: 29%;
  left: 2.6041666667vw;
}

@media (max-width: 1199px) {
  .winner-user:nth-child(2) {
    top: 25%;
    left: 30px;
  }
}

@media (min-width: 1200px) {
  .winner-user:nth-child(2) .user-photo {
    flex: 0 0 3.59375vw;
    height: 3.59375vw;
  }
}

.winner-user:nth-child(2) .user-photo:before {
  background-color: #93aab5;
}

@media (min-width: 1200px) {
  .winner-user:nth-child(2) .user-photo:before {
    width: 0.8333333333vw;
    height: 1.1458333333vw;
    font-size: 0.659375vw;
  }
}

.winner-user:nth-child(3) {
  top: 46%;
  left: 3.8541666667vw;
}

@media (max-width: 1199px) {
  .winner-user:nth-child(3) {
    top: 42%;
    left: 56px;
  }
}

@media (min-width: 1200px) {
  .winner-user:nth-child(3) .user-photo {
    flex: 0 0 3.28125vw;
    height: 3.28125vw;
  }
}

.winner-user:nth-child(3) .user-photo:before {
  background-color: #b97625;
}

@media (min-width: 1200px) {
  .winner-user:nth-child(3) .user-photo:before {
    width: 0.78125vw;
    height: 1.0416666667vw;
    font-size: 0.6041666667vw;
  }
}

.winner-user:nth-child(4) {
  top: 62%;
  left: 3.125vw;
}

@media (max-width: 1199px) {
  .winner-user:nth-child(4) {
    top: 58%;
    left: 40px;
  }
}

.winner-user:nth-child(5) {
  top: 74%;
  left: 0.46875vw;
}

@media (max-width: 1199px) {
  .winner-user:nth-child(5) {
    left: 0;
  }
}

.user-photo {
  flex: 0 0 2.9166666667vw;
  width: 2.9166666667vw;
  height: 2.9166666667vw;
  position: relative;
}

@media (max-width: 1199px) {
  .user-photo {
    flex: 0 0 56px;
    height: 56px;
    width: 56px;
  }
}

.user-photo:before {
  content: counter(position);
  counter-increment: position;
  position: absolute;
  top: 95%;
  left: 50%;
  width: 0.6770833333vw;
  height: 0.9375vw;
  background-color: #415d8f;
  transform: translateX(-50%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 85%, 0 100%);
  font: 0.5208333333vw "Raleway Medium";
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1199px) {
  .user-photo:before {
    width: 13px;
    height: 18px;
    font-size: 10px;
  }
}

.user-photo img {
  border: 0.15625vw solid #28406e;
  border-radius: 50%;
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

@media (max-width: 1199px) {
  .user-photo img {
    border-width: 3px;
  }
}

.user-info {
  padding-left: 0.9375vw;
  white-space: nowrap;
  font: 0.78125vw "Raleway Medium";
  position: relative;
  z-index: 2;
}

@media (max-width: 1199px) {
  .user-info {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .user-info {
    display: none;
  }
}

.user-info p {
  color: #ffd22e;
}

.all-users {
  position: absolute;
  top: 84%;
  left: -2.6041666667vw;
  font-family: "Raleway SemiBold";
  background-color: #9037d5;
  border-radius: 50%;
  width: 2.0833333333vw;
  height: 2.0833333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

@media (max-width: 1199px) {
  .all-users {
    top: 88%;
    left: -40px;
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 767px) {
  .all-users {
    position: static;
  }
}

.all-users:hover {
  background-color: #ff26ef;
}

.banner-menu {
  margin-top: -7.03125vw;
  display: flex;
}

@media (max-width: 1199px) {
  .banner-menu {
    margin-top: 20px;
    justify-content: center;
  }
}

.banner-menu li {
  padding: 0 1.5625vw 0.7291666667vw;
  text-transform: uppercase;
  font: 1.09375vw "Bebas Neue";
  color: #7a8595;
  cursor: pointer;
  position: relative;
  transition: color .3s ease-in-out;
}

@media (max-width: 1199px) {
  .banner-menu li {
    padding: 0 20px 10px;
    font-size: 19px;
  }
}

.banner-menu li:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  left: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.1));
}

.banner-menu li:first-child {
  padding-left: 0;
}

.banner-menu li:last-child {
  padding-right: 0;
}

.banner-menu li:hover {
  color: #fff;
}

.banner-menu li.active {
  color: #fff;
}

.banner-menu li.active:first-child span:before {
  background-color: #ff26ef;
}

.banner-menu li.active:last-child span:before {
  background-color: #3cdd4a;
}

.banner-menu span {
  position: relative;
}

.banner-menu span:before {
  content: '';
  position: absolute;
  bottom: -0.7291666667vw;
  width: 0.2604166667vw;
  height: 0.2604166667vw;
  transform: translate(-50%, 50%);
  left: 50%;
  background-color: #9da3ac;
  z-index: 2;
}

@media (max-width: 1199px) {
  .banner-menu span:before {
    bottom: -10px;
    width: 5px;
    height: 5px;
  }
}

.block-feature {
  padding-bottom: 1.5625vw;
}

@media (max-width: 1199px) {
  .block-feature {
    padding-bottom: 30px;
  }
}

.feature-game__slider {
  margin: 0 -1.8229166667vw;
}

@media (max-width: 1199px) {
  .feature-game__slider {
    margin: 0 -15px;
  }
}

.feature-game__col {
  padding: 1.3020833333vw 1.8229166667vw 0;
  position: relative;
}

@media (max-width: 1199px) {
  .feature-game__col {
    padding: 12px 15px 0;
  }
}

.feature-game:hover .feature-game__name a {
  color: #ff26ef;
}

.feature-game:hover .feature-game__num {
  -webkit-text-fill-color: #ff26ef;
  -webkit-text-stroke: 2px #ff26ef;
}

.feature-game__num {
  position: absolute;
  top: 2px;
  left: 0;
  font: 9.375vw "Bebas Neue";
  line-height: .8;
  -webkit-text-stroke: 2px #273545;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  transition: all .3s ease-in-out;
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 3px, #1b2549 3px, #1b2549 5px);
}

@media (max-width: 1199px) {
  .feature-game__num {
    font-size: 80px;
  }
}

.feature-game__img {
  height: 13.0208333333vw;
  overflow: hidden;
  border-radius: 2.6041666667vw;
  border: 0.3645833333vw solid #28416f;
  display: block;
  margin-bottom: 1.0416666667vw;
  position: relative;
}

@media (max-width: 1199px) {
  .feature-game__img {
    height: 180px;
    border-radius: 30px;
    border-width: 4px;
    margin-bottom: 15px;
  }
}

.feature-game__img img {
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.feature-game__name {
  font: 1.1458333333vw "Bebas Neue";
  text-align: center;
}

@media (max-width: 1199px) {
  .feature-game__name {
    font-size: 18px;
  }
}

.feature-game__name a {
  transition: color .3s ease-in-out;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 2;
}

.slider-arrow__prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

.slider-arrow__next {
  right: 0;
  transform: translateY(-50%);
}

.block-tournaments {
  padding: 1.5625vw 0;
  position: relative;
  transform-style: preserve-3d;
}

@media (max-width: 1199px) {
  .block-tournaments {
    padding: 30px 0;
  }
}

.block-tournaments:before {
  content: '';
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 54.21875vw;
  background: url("../images/bg/bg-tournament.png") center/contain no-repeat;
  transform: translateZ(-1px);
}

@media (max-width: 1199px) {
  .block-tournaments:before {
    display: none;
  }
}

.tournament {
  position: relative;
  background-color: #1b2633;
  padding: 1.0416666667vw 1.6666666667vw;
  border-radius: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .tournament {
    padding: 20px 30px;
    border-radius: 10px;
  }
}

.tournament:before {
  content: '';
  position: absolute;
  top: 0.4166666667vw;
  left: 0.4166666667vw;
  width: 1.6666666667vw;
  height: 1.0416666667vw;
  background: url("../images/other/bg-tournament.png") center/contain no-repeat;
}

@media (max-width: 1199px) {
  .tournament:before {
    top: 5px;
    left: 5px;
    width: 32px;
    height: 20px;
  }
}

.tournament .btn {
  display: flex;
  margin: 0 auto;
}

.tournament .btn__blue {
  width: 6.25vw;
  height: 2.2916666667vw;
  font: 1.0416666667vw "Bebas Neue";
  box-shadow: 0 0 1.25vw 0.625vw rgba(55, 163, 255, 0.23);
}

@media (max-width: 1199px) {
  .tournament .btn__blue {
    width: 120px;
    height: 44px;
    font: 20px "Bebas Neue";
    box-shadow: 0 0 24px 12px rgba(55, 163, 255, 0.23);
  }
}

.tournament .btn__blue i {
  flex: 0 0 1.4583333333vw;
  height: 1.4583333333vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4490f8;
  font-size: 0.7291666667vw;
}

@media (max-width: 1199px) {
  .tournament .btn__blue i {
    font-size: 14px;
    height: 28px;
    flex: 0 0 28px;
  }
}

.tournament .btn__blue:hover {
  box-shadow: none;
}

.tournament .btn__blue:hover i {
  color: #fff;
}

.tournament .btn__gray {
  width: 8.3333333333vw;
  height: 2.2916666667vw;
  font: 0.78125vw "Raleway Medium";
}

@media (max-width: 1199px) {
  .tournament .btn__gray {
    width: 160px;
    height: 44px;
    font: 15px "Raleway Medium";
  }
}

.tournament-jackpot {
  position: absolute;
  top: -0.5208333333vw;
  right: -2.0833333333vw;
  background-color: #273545;
  border-radius: 0.78125vw;
  padding: 0.4166666667vw 2.4479166667vw 0.4166666667vw 0.5208333333vw;
}

@media (max-width: 1199px) {
  .tournament-jackpot {
    top: -10px;
    right: 0;
    border-radius: 10px;
    padding: 5px 30px 5px 8px;
  }
}

.tournament-jackpot h3 {
  font: 1.6145833333vw "Bebas Neue";
  color: #f0b500;
  line-height: 1;
}

@media (max-width: 1199px) {
  .tournament-jackpot h3 {
    font-size: 22px;
  }
}

.tournament-jackpot h3 span {
  color: #fff;
}

.tournament-jackpot p {
  color: #3cdd4a;
  font: 0.6770833333vw "Avenir Next Cyr Demi";
}

@media (max-width: 1199px) {
  .tournament-jackpot p {
    font-size: 13px;
  }
}

.tournament-jackpot img {
  position: absolute;
  width: 3.1770833333vw;
  top: -1.875vw;
  right: -1.3020833333vw;
}

@media (max-width: 1199px) {
  .tournament-jackpot img {
    width: 50px;
    top: -35px;
    right: -12px;
  }
}

.tournament-img {
  width: 8.8541666667vw;
  height: 8.8541666667vw;
  margin: 0 auto 1.3020833333vw;
  border-radius: 1.8229166667vw;
  overflow: hidden;
  box-shadow: 0 0.5208333333vw 1.8229166667vw rgba(16, 14, 20, 0.45);
}

@media (max-width: 1199px) {
  .tournament-img {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(16, 14, 20, 0.45);
  }
}

.tournament-img__active {
  position: relative;
}

.tournament-img__active:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 222, 78, 0.7);
}

.tournament-img img {
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.tournament-progress {
  position: relative;
  background-color: #10121f;
  border: 1px solid #10121f;
  border-radius: 0.78125vw;
  height: 0.9375vw;
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .tournament-progress {
    height: 14px;
    border-radius: 15px;
    margin-bottom: 10px;
  }
}

.progress-line {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f0b500;
  height: 100%;
  border-radius: 0.78125vw;
}

@media (max-width: 1199px) {
  .progress-line {
    border-radius: 15px;
  }
}

.progress-line:before, .progress-line:after {
  position: absolute;
  right: 0;
}

.progress-line:before {
  content: '\e901';
  top: 0;
  width: 0.9375vw;
  height: 0.9375vw;
  background-color: #10121f;
  border-radius: 50%;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'icomoon';
  font-size: 0.8333333333vw;
  animation: 2s linear tada infinite;
}

@media (max-width: 1199px) {
  .progress-line:before {
    font-size: 12px;
    width: 14px;
    height: 14px;
  }
}

.progress-line:after {
  content: attr(data-hours);
  font: 0.5729166667vw "Avenir Next Cyr Medium";
  color: #9bafc8;
  top: -1.0416666667vw;
  transform: translateX(50%);
  width: max-content;
}

@media (max-width: 1199px) {
  .progress-line:after {
    font-size: 11px;
    top: -18px;
  }
}

.tournament-title {
  text-align: center;
  margin-bottom: 0.4166666667vw;
}

@media (max-width: 1199px) {
  .tournament-title {
    margin-bottom: 8px;
  }
}

.tournament-title__uppercase {
  font: 1.1458333333vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .tournament-title__uppercase {
    font-size: 18px;
  }
}

.tournament-title__standard {
  font: 0.78125vw "Avenir Next Cyr Medium";
}

@media (max-width: 1199px) {
  .tournament-title__standard {
    font-size: 14px;
  }
}

.tournament-position {
  text-align: center;
  font: 0.78125vw "Raleway Medium";
  color: #9da3ac;
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .tournament-position {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.tournament-position span {
  color: #fff;
  font-family: "Raleway SemiBold";
}

.tournament-time {
  text-align: center;
  margin-bottom: 0.78125vw;
  font: 1.1458333333vw "Raleway Medium";
}

@media (max-width: 1199px) {
  .tournament-time {
    font-size: 18px;
  }
}

.tournament-time span {
  color: #3cdd4a;
}

.block-btn {
  text-align: center;
}

.block-btn .btn {
  width: 12.8645833333vw;
  height: 2.6041666667vw;
  font: 1.1458333333vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .block-btn .btn {
    width: 247px;
    height: 50px;
    font: 22px "Bebas Neue";
  }
}

.block-games {
  padding: 1.5625vw 0 6.5104166667vw;
  background: url("../images/bg/bg-game.png") center no-repeat;
}

@media (max-width: 1199px) {
  .block-games {
    padding: 30px 0 80px;
  }
}

@media (max-width: 767px) {
  .block-games {
    background: none;
    padding: 30px 0;
  }
}

.block-games .row {
  margin: 0 -1.5625vw;
}

@media (max-width: 1199px) {
  .block-games .row {
    margin: 0 -15px;
  }
}

.block-games [class^="col-"] {
  padding: 0 1.5625vw;
}

@media (max-width: 1199px) {
  .block-games [class^="col-"] {
    padding: 0 15px;
  }
}

.game {
  padding: 1.3020833333vw;
  background-color: #202b3a;
  border-radius: 1.3020833333vw;
  transition: all .3s ease-in-out;
}

@media (max-width: 1199px) {
  .game {
    padding: 25px;
    border-radius: 25px;
  }
}

.game:hover {
  box-shadow: 0 0 24px rgba(255, 255, 255, 0.4);
}

.game:hover .game-name a {
  color: #ff26ef;
}

.game-img {
  border-radius: 1.0416666667vw;
  overflow: hidden;
  display: block;
  margin-bottom: 0.5208333333vw;
  height: 7.8125vw;
  box-shadow: 0 0.5208333333vw 1.3020833333vw rgba(16, 14, 20, 0.45);
}

@media (max-width: 1199px) {
  .game-img {
    border-radius: 20px;
    margin-bottom: 10px;
    height: 150px;
    box-shadow: 0 10px 25px rgba(16, 14, 20, 0.45);
  }
}

.game-img img {
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.game-name {
  text-align: center;
  font: 1.0416666667vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .game-name {
    font-size: 20px;
  }
}

.game-name a {
  transition: color .3s ease-in-out;
}

.block-download {
  padding: 2.6041666667vw 0;
  background: url("../images/bg/bg-download.jpg") left center/cover;
}

@media (max-width: 1199px) {
  .block-download {
    padding: 30px 0;
  }
}

.block-download .container {
  position: relative;
}

.download-text {
  width: 27.6041666667vw;
  padding-left: 4.6875vw;
}

@media (max-width: 1199px) {
  .download-text {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .download-text {
    width: 100%;
    text-align: center;
  }
}

.download-text h2 {
  font: 4.5833333333vw "Bebas Neue";
  line-height: .9;
}

@media (max-width: 1199px) {
  .download-text h2 {
    font-size: 44px;
  }
}

.download-text h2 span {
  -webkit-text-stroke: 1px #babac0;
  -webkit-text-fill-color: transparent;
}

.download-text h3 {
  line-height: .9;
  font: 1.3541666667vw "Bebas Neue";
  color: rgba(255, 255, 255, 0.83);
  letter-spacing: 0.1041666667vw;
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .download-text h3 {
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }
}

.download-icon {
  display: flex;
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .download-icon {
    margin-bottom: 15px;
  }
}

@media (max-width: 991px) {
  .download-icon {
    justify-content: center;
  }
}

.download-icon a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.4375vw;
  height: 3.4375vw;
  padding: 0.2083333333vw;
  margin-right: 0.8333333333vw;
  border-radius: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .download-icon a {
    width: 60px;
    height: 60px;
    padding: 4px;
    margin-right: 10px;
    border-radius: 5px;
  }
}

.download-icon:last-child {
  margin-right: 0;
}

.download-logo {
  background-color: #2280ff;
}

.download-code {
  background-color: #fff;
}

.download-btn {
  display: flex;
}

@media (max-width: 991px) {
  .download-btn {
    justify-content: center;
  }
}

@media (max-width: 567px) {
  .download-btn {
    flex-direction: column;
    align-items: center;
  }
}

.download-btn a {
  display: flex;
  width: 9.8958333333vw;
  height: 2.7083333333vw;
  font: 0.9375vw "Raleway SemiBold";
  margin-right: 1.5625vw;
  border: 2px solid #2280ff;
}

@media (max-width: 1199px) {
  .download-btn a {
    width: 190px;
    height: 52px;
    font: 18px "Raleway SemiBold";
    margin-right: 20px;
  }
}

@media (max-width: 567px) {
  .download-btn a {
    margin: 0 0 10px;
  }
}

.download-btn a:last-child {
  margin-right: 0;
}

.download-btn a:hover {
  background-color: #fff;
  border-color: #fff;
  color: #101d34;
}

.download-btn a i {
  margin-right: 0.3645833333vw;
}

@media (max-width: 1199px) {
  .download-btn a i {
    margin-right: 7px;
  }
}

.download-img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 39.4270833333vw;
}

@media (max-width: 1199px) {
  .download-img {
    width: 45%;
  }
}

@media (max-width: 991px) {
  .download-img {
    display: none;
  }
}

.block-advantage {
  padding: 5.1041666667vw 0 1.8229166667vw;
  background: url("../images/bg/bg-advantage.png") center bottom/cover;
}

@media (max-width: 1199px) {
  .block-advantage {
    padding: 40px 0;
  }
}

.advantage-row {
  display: flex;
  align-items: center;
}

.advantage-img {
  flex: 0 0 30.6770833333vw;
  width: 30.6770833333vw;
  margin: 0 4.1666666667vw 0 8.8541666667vw;
}

@media (max-width: 1199px) {
  .advantage-img {
    flex: 0 0 45%;
    width: 45%;
    margin: 30px 0 0;
  }
}

@media (max-width: 567px) {
  .advantage-img {
    display: none;
  }
}

.advantage-text {
  width: 28.3854166667vw;
}

@media (max-width: 1199px) {
  .advantage-text {
    width: auto;
  }
}

.advantage-text h2 {
  font: 2.9166666667vw "Bebas Neue";
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .advantage-text h2 {
    font-size: 42px;
    margin-bottom: 15px;
  }
}

.advantage-text h2 span {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #949699;
}

.advantage-text .btn {
  width: 14.5833333333vw;
  height: 3.6458333333vw;
  font: 1.6666666667vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .advantage-text .btn {
    width: 180px;
    height: 46px;
    font: 24px "Bebas Neue";
  }
}

footer {
  padding: 1.9791666667vw 0 1.3020833333vw;
  background-color: #202936;
}

@media (max-width: 1199px) {
  footer {
    padding: 20px 0;
  }
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 8.3333333333vw;
}

@media (max-width: 1199px) {
  .footer-row {
    padding: 0;
  }
}

@media (max-width: 991px) {
  .footer-row {
    flex-direction: column;
  }
}

.footer-l {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  width: 8.3333333333vw;
  margin-bottom: 0.78125vw;
}

@media (max-width: 1199px) {
  .footer-logo {
    width: 120px;
    margin-bottom: 10px;
  }
}

.soc {
  display: flex;
}

.soc a {
  margin: 0 0.15625vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.9791666667vw;
  height: 1.9791666667vw;
  border-radius: 0.2604166667vw;
  background-color: #313f53;
  transition: all .3s ease-in-out;
}

@media (max-width: 1199px) {
  .soc a {
    margin: 0 3px;
    width: 38px;
    height: 38px;
    border-radius: 5px;
  }
}

.soc a:hover {
  color: #ff26ef;
}

.footer-widgets {
  width: 37.5vw;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1199px) {
  .footer-widgets {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .footer-widgets {
    width: 100%;
  }
}

@media (max-width: 567px) {
  .footer-widgets {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.footer-menu {
  padding-top: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .footer-menu {
    padding-top: 15px;
  }
}

.footer-menu li {
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .footer-menu li {
    margin-bottom: 10px;
  }
}

.footer-menu a {
  font-size: 0.9375vw;
  color: rgba(255, 255, 255, 0.57);
  transition: color .3s ease-in-out;
}

@media (max-width: 1199px) {
  .footer-menu a {
    font-size: 16px;
  }
}

.footer-menu a:hover {
  color: #ff26ef;
}

.copyright {
  margin-top: 1.8229166667vw;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.25);
}

@media (max-width: 1199px) {
  .copyright {
    padding-top: 20px;
  }
}

.block-table {
  padding: 4.1666666667vw 0;
  background: url("../images/bg/bg-pages.png") center top/100% no-repeat;
}

@media (max-width: 1199px) {
  .block-table {
    padding: 40px 0;
  }
}

.table-wrap {
  margin: 0 auto;
}

.table-wrap__w990 {
  width: 51.5625vw;
}

.table-wrap__w830 {
  width: 43.2291666667vw;
}

@media (max-width: 1199px) {
  .table-wrap {
    width: 100%;
    padding: 0 15px;
  }
}

.table-menu {
  display: flex;
  margin-bottom: 2.7083333333vw;
}

@media (max-width: 1199px) {
  .table-menu {
    margin-bottom: 30px;
  }
}

.table-menu li {
  width: 50%;
  text-align: center;
  cursor: pointer;
  font: 2.0833333333vw "Bebas Neue";
  color: rgba(157, 163, 172, 0.5);
  transition: color .3s ease-in-out;
}

@media (max-width: 1199px) {
  .table-menu li {
    font-size: 26px;
  }
}

.table-menu li:first-child span:before {
  background-color: #ff26ef;
}

.table-menu li:last-child span:before {
  background-color: #3cdd4a;
}

.table-menu li.active, .table-menu li:hover {
  color: #9da3ac;
}

.table-menu li.active span:before, .table-menu li:hover span:before {
  width: 100%;
  left: 0;
}

.table-menu span {
  padding-bottom: 1.0416666667vw;
  position: relative;
  display: inline-block;
}

@media (max-width: 1199px) {
  .table-menu span {
    padding-bottom: 8px;
  }
}

.table-menu span:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0.2604166667vw;
  transition: all .3s ease-in-out;
}

@media (max-width: 1199px) {
  .table-menu span:before {
    height: 3px;
  }
}

.winner-table {
  width: 100%;
}

.t-head {
  display: flex;
  font: 1.5625vw "Bebas Neue";
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .t-head {
    font-size: 18px;
  }
}

.t-row {
  display: flex;
  align-items: center;
  font: 1.0416666667vw "Bebas Neue";
  color: rgba(255, 255, 255, 0.5);
  padding: 0.78125vw 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: background-color .3s ease-in-out;
}

@media (max-width: 1199px) {
  .t-row {
    font-size: 16px;
    padding: 10px 0;
  }
}

.t-row:hover {
  background-color: rgba(255, 255, 255, 0.4);
  border-bottom-color: transparent;
  border-radius: 0.4166666667vw;
}

@media (max-width: 1199px) {
  .t-row:hover {
    border-radius: 8px;
  }
}

.t-row.my-position {
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 1.25vw;
  color: #fff;
}

.t-row.my-position:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 1199px) {
  .t-row.my-position {
    font-size: 16px;
  }
}

.t-row.my-position .table-user img {
  flex: 0 0 3.2291666667vw;
  height: 3.2291666667vw;
  width: 3.2291666667vw;
}

@media (max-width: 1199px) {
  .t-row.my-position .table-user img {
    flex: 0 0 32px;
    height: 32px;
    width: 32px;
  }
}

.t-contest {
  width: 45.5%;
  padding: 0 0.5208333333vw 0 1.3020833333vw;
}

@media (max-width: 1199px) {
  .t-contest {
    padding: 0 10px;
  }
}

@media (max-width: 567px) {
  .t-contest {
    width: 33.5%;
  }
}

.t-date {
  width: 33.5%;
}

.t-cash {
  width: 21%;
  text-align: center;
}

@media (max-width: 567px) {
  .t-cash {
    width: 33%;
  }
}

.t-user {
  width: 45.5%;
  padding: 0 0.5208333333vw 0 1.3020833333vw;
}

@media (max-width: 1199px) {
  .t-user {
    padding: 0 10px;
  }
}

@media (max-width: 567px) {
  .t-user {
    width: 48%;
  }
}

.t-prize {
  width: 33.5%;
}

@media (max-width: 567px) {
  .t-prize {
    width: 19%;
  }
}

.t-game {
  display: block;
  width: 2.3958333333vw;
  height: 2.3958333333vw;
  border-radius: 0.5208333333vw;
  overflow: hidden;
  box-shadow: 0 0.5208333333vw 1.8229166667vw rgba(16, 14, 20, 0.45);
}

@media (max-width: 1199px) {
  .t-game {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    box-shadow: 0 8px 22px rgba(16, 14, 20, 0.45);
  }
}

.table-user {
  display: inline-flex;
  align-items: center;
}

.table-user img {
  flex: 0 0 2.3958333333vw;
  height: 2.3958333333vw;
  width: 2.3958333333vw;
  border-radius: 50%;
  margin-right: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .table-user img {
    flex: 0 0 32px;
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}

.my-cash {
  display: inline-flex;
  align-items: center;
}

.my-cash img {
  width: 1.7708333333vw;
  margin-left: 0.3645833333vw;
}

@media (max-width: 1199px) {
  .my-cash img {
    width: 28px;
    margin-left: 5px;
  }
}

.table-info {
  text-align: center;
  font-family: "Bebas Neue";
  margin-bottom: 2.0833333333vw;
}

@media (max-width: 1199px) {
  .table-info {
    margin-bottom: 20px;
  }
}

.table-info h2, .table-info h3 {
  font-size: 2.0833333333vw;
}

@media (max-width: 1199px) {
  .table-info h2, .table-info h3 {
    font-size: 28px;
  }
}

.table-info h2 span {
  color: #f0b500;
}

.table-info h3 span {
  color: #3cdd4a;
}

.table-info p {
  font-size: 1.25vw;
  color: #9da3ac;
}

@media (max-width: 1199px) {
  .table-info p {
    font-size: 18px;
  }
}

.table-game {
  display: block;
  width: 9.1666666667vw;
  height: 9.1666666667vw;
  margin: 0 auto 1.0416666667vw;
  border-radius: 2.6041666667vw;
  border: 0.3645833333vw solid #28416f;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .table-game {
    width: 120px;
    height: 120px;
    margin: 0 auto 20px;
    border-radius: 30px;
    border-width: 4px;
  }
}

.table-game img {
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.fancybox-is-open .fancybox-bg {
  background: #202936;
}

.fancybox-slide--html .fancybox-close-small {
  width: 1.9270833333vw;
  height: 1.9270833333vw;
  padding: 0.2604166667vw;
  background-image: linear-gradient(111deg, #f15050, #f31616);
  border-radius: 50%;
}

@media (max-width: 1199px) {
  .fancybox-slide--html .fancybox-close-small {
    width: 20px;
    height: 20px;
    padding: 2px;
  }
}

.popup-style {
  padding: 2.0833333333vw;
  background-color: transparent;
}

@media (max-width: 1199px) {
  .popup-style {
    padding: 25px;
  }
}

.popup-style__w600 {
  width: 31.25vw;
}

@media (max-width: 1199px) {
  .popup-style__w600 {
    width: 590px;
  }
}

.popup-style__w900 {
  width: 46.875vw;
}

@media (max-width: 1199px) {
  .popup-style__w900 {
    width: 870px;
  }
}

.popup-style__pt {
  padding-top: 2.8645833333vw;
}

@media (max-width: 1199px) {
  .popup-style__pt {
    padding-top: 35px;
  }
}

.popup-style__text-center {
  text-align: center;
}

.popup-style .btn__icon--r i {
  margin-left: 0.5729166667vw;
  flex: 0 0 1.7708333333vw;
  width: 1.7708333333vw;
  height: 1.7708333333vw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .popup-style .btn__icon--r i {
    margin-left: 8px;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    font-size: 14px;
  }
}

.popup-box {
  background: #1c2734 url("../images/bg/bg-popup.png") center top/cover no-repeat;
  padding: 2.0833333333vw;
  border-radius: 1.0416666667vw;
  box-shadow: 0 0.2083333333vw 1.25vw rgba(0, 0, 0, 0.5);
  position: relative;
}

@media (max-width: 1199px) {
  .popup-box {
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.5);
  }
}

.popup-top {
  margin-top: -3.6458333333vw;
}

@media (max-width: 1199px) {
  .popup-top {
    margin-top: -40px;
  }
}

.popup-top__img {
  width: 9.0104166667vw;
  height: 9.0104166667vw;
  margin: 0 auto 1.5625vw;
  border-radius: 2.0833333333vw;
  border: 0.3645833333vw solid #28416f;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .popup-top__img {
    width: 110px;
    height: 110px;
    margin: 0 auto 20px;
    border-radius: 20px;
    border-width: 4px;
  }
}

.popup-top__img--logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, #cb316a, #9037d5);
}

.popup-top__img--logo img {
  width: 4.8958333333vw;
}

@media (max-width: 1199px) {
  .popup-top__img--logo img {
    width: 70px;
  }
}

.popup-top__img--picture img {
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.popup-title {
  font-family: "Bebas Neue";
}

.popup-title__f45 {
  font-size: 2.34375vw;
  margin-bottom: 1.5625vw;
}

@media (max-width: 1199px) {
  .popup-title__f45 {
    font-size: 32px;
    margin-bottom: 10px;
  }
}

.popup-title__f22 {
  font-size: 1.1458333333vw;
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .popup-title__f22 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.btn-back {
  width: 226px;
  height: 55px;
  font: 24px "Bebas Neue";
}

@media (max-width: 1199px) {
  .btn-back {
    width: 180px;
    height: 46px;
    font: 18px "Bebas Neue";
  }
}

.btn-watch {
  width: 7.6041666667vw;
  height: 2.8645833333vw;
  font: 1.25vw "Bebas Neue";
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .btn-watch {
    width: 120px;
    height: 42px;
    font: 18px "Bebas Neue";
    margin-bottom: 10px;
  }
}

.popup-text {
  font-family: "Bebas Neue";
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .popup-text {
    margin-bottom: 10px;
  }
}

.popup-text h2 {
  font-size: 2.6041666667vw;
}

@media (max-width: 1199px) {
  .popup-text h2 {
    font-size: 32px;
  }
}

.popup-text h3 {
  font-size: 1.5625vw;
  line-height: 1;
}

@media (max-width: 1199px) {
  .popup-text h3 {
    font-size: 22px;
  }
}

.popup-text p {
  font-size: 1.09375vw;
  color: #9da3ac;
  letter-spacing: 1px;
}

@media (max-width: 1199px) {
  .popup-text p {
    font-size: 18px;
  }
}

.btn-register {
  width: 10.2083333333vw;
  height: 2.9166666667vw;
  font: 1.25vw "Bebas Neue";
  margin-bottom: 0.78125vw;
}

@media (max-width: 1199px) {
  .btn-register {
    margin-bottom: 10px;
    width: 160px;
    height: 46px;
    font: 18px "Bebas Neue";
  }
}

.popup-welcome {
  font: 0.7291666667vw "Bebas Neue";
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.5px;
  margin-bottom: 1.3020833333vw;
}

@media (max-width: 1199px) {
  .popup-welcome {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.popup-welcome img {
  margin-left: 0.2604166667vw;
  width: 1.25vw;
}

@media (max-width: 1199px) {
  .popup-welcome img {
    margin-left: 5px;
    width: 20px;
  }
}

.popup-thank {
  font: 1.09375vw "Bebas Neue";
  color: #7a8595;
}

@media (max-width: 1199px) {
  .popup-thank {
    font-size: 18px;
  }
}

.popup-thank a {
  color: #3cdd4a;
  text-decoration: underline;
}

.popup-thank a:hover {
  text-decoration: none;
}

.register-text {
  margin-bottom: 1.0416666667vw;
  font: 1.5625vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .register-text {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.register-soc {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2.6041666667vw;
}

@media (max-width: 1199px) {
  .register-soc {
    margin-bottom: 15px;
  }
}

.register-soc a {
  margin: 0 0.4166666667vw 0.5208333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.7708333333vw;
  height: 3.3854166667vw;
  border-radius: 1.8229166667vw;
  transition: opacity .3s ease-in-out;
  padding: 10px 30px;
}

@media (max-width: 1199px) {
  .register-soc a {
    margin: 0 4px 10px;
    width: 90px;
    height: 46px;
    border-radius: 30px;
    padding: 0 20px;
  }
}

.register-soc a:hover {
  opacity: .6;
}

.register-soc a.register-mail {
  background-image: linear-gradient(-45deg, #1e55ee, #19e3ff);
}

.register-soc a.register-facebook {
  background-color: #3e59a2;
}

.register-soc a.register-google {
  background-color: #fff;
}

.register-log {
  font: 1.09375vw "Bebas Neue";
  margin-bottom: 0.5208333333vw;
  color: #9da3ac;
  letter-spacing: 1px;
}

@media (max-width: 1199px) {
  .register-log {
    font-size: 18px;
    margin-bottom: 8px;
  }
}

.btn-login {
  width: 7.8125vw;
  height: 2.6041666667vw;
  font: 1.25vw "Bebas Neue";
  margin-bottom: 1.5625vw;
}

@media (max-width: 1199px) {
  .btn-login {
    width: 120px;
    height: 42px;
    font: 18px "Bebas Neue";
    margin-bottom: 10px;
  }
}

.popup-bottom__link {
  font-size: 0.7291666667vw;
  color: #7a8595;
}

@media (max-width: 1199px) {
  .popup-bottom__link {
    font-size: 14px;
  }
}

.popup-bottom__link a {
  text-decoration: underline;
}

.popup-bottom__link a:hover {
  text-decoration: none;
}

.style-form {
  max-width: 18.2291666667vw;
  margin: 0 auto 1.8229166667vw;
}

@media (max-width: 1199px) {
  .style-form {
    max-width: 350px;
    margin: 0 auto 15px;
  }
}

.style-form input:not([type="submit"]) {
  display: block;
  width: 100%;
  height: 2.5vw;
  border-radius: 0.2604166667vw;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #fff;
  font-size: 0.7291666667vw;
  margin-bottom: 0.625vw;
}

@media (max-width: 1199px) {
  .style-form input:not([type="submit"]) {
    height: 48px;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.style-form input:not([type="submit"])::placeholder {
  color: #7a8595;
}

.style-form .form-pay input {
  display: none;
}

.style-form .btn {
  margin-top: 2.0833333333vw;
  width: 10.4166666667vw;
  height: 2.8125vw;
  font: 1.25vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .style-form .btn {
    margin-top: 20px;
    width: 160px;
    height: 42px;
    font: 18px "Bebas Neue";
  }
}

.style-form .avatar input {
  display: none;
}

.style-form h2 {
  font: 1.1458333333vw "Bebas Neue";
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .style-form h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.field {
  position: relative;
}

.field input {
  padding: 0 0.5208333333vw;
}

@media (max-width: 1199px) {
  .field input {
    padding: 0 10px;
  }
}

.field__icon input {
  padding: 0 2.0833333333vw;
}

@media (max-width: 1199px) {
  .field__icon input {
    padding: 0 30px;
  }
}

.field i {
  color: #7a8595;
  position: absolute;
  top: 50%;
  left: 0.8854166667vw;
  transform: translateY(-50%);
  font-size: 0.8333333333vw;
}

@media (max-width: 1199px) {
  .field i {
    left: 8px;
    font-size: 16px;
  }
}

@media (max-width: 567px) {
  .field i {
    display: none;
  }
}

.avatar {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  cursor: pointer;
  width: 7.1875vw;
  height: 7.1875vw;
  margin: 0 auto 1.875vw;
  border-radius: 1.5625vw;
  border: 0.3645833333vw solid #28416f;
  font-size: 0.625vw;
  color: rgba(122, 133, 149, 0.5);
  overflow: hidden;
}

@media (max-width: 1199px) {
  .avatar {
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
    border-radius: 30px;
    border-width: 4px;
    font-size: 12px;
  }
}

.avatar img {
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  display: none;
}

.avatar-text {
  padding: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .avatar-text {
    padding: 10px;
  }
}

.plus {
  width: 1.40625vw;
  height: 1.40625vw;
  margin: 0 auto;
  position: relative;
  display: block;
}

@media (max-width: 1199px) {
  .plus {
    width: 22px;
    height: 22px;
  }
}

.plus:before, .plus:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 0.2604166667vw;
  background-color: #656e7c;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  border-radius: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .plus:before, .plus:after {
    height: 3px;
    border-radius: 5px;
  }
}

.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.form-balance {
  font-size: 0.7291666667vw;
  color: #3cdd4a;
  margin-bottom: 2.0833333333vw;
}

@media (max-width: 1199px) {
  .form-balance {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.form-pay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.78125vw;
}

@media (max-width: 1199px) {
  .form-pay {
    margin-bottom: 10px;
  }
}

@media (max-width: 567px) {
  .form-pay {
    flex-direction: column;
    align-items: center;
  }
}

.form-pay input:checked ~ .pay-img {
  opacity: 1;
}

.pay-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.1770833333vw;
  height: 3.125vw;
  border-radius: 2.0833333333vw;
  background-color: #bbbec2;
  padding: 0 0.78125vw;
  cursor: pointer;
  margin-bottom: 0.5208333333vw;
  opacity: .7;
}

@media (max-width: 1199px) {
  .pay-img {
    margin-bottom: 8px;
    width: 140px;
    height: 46px;
    border-radius: 30px;
    padding: 0 15px;
  }
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  transform: translateY(-50%);
  color: #7a8595;
  transition: all .3s ease-in-out;
}

.toggle-password:hover {
  color: #fff;
}

.congratulations-subtitle {
  font-size: 0.7291666667vw;
  margin-bottom: 1.0416666667vw;
  color: #707b8b;
}

@media (max-width: 1199px) {
  .congratulations-subtitle {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.congratulations-text {
  font-family: "Bebas Neue";
  letter-spacing: 1px;
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .congratulations-text {
    margin-bottom: 10px;
  }
}

.congratulations-text h3 {
  font-size: 1.25vw;
}

@media (max-width: 1199px) {
  .congratulations-text h3 {
    font-size: 18px;
  }
}

.congratulations-text p {
  font-size: 0.9375vw;
  color: #9da3ac;
}

@media (max-width: 1199px) {
  .congratulations-text p {
    font-size: 16px;
  }
}

.congratulations-cash {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5625vw;
  font: 3.59375vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .congratulations-cash {
    margin-bottom: 15px;
    font-size: 46px;
  }
}

.congratulations-cash img {
  margin-left: 0.625vw;
  width: 2.8125vw;
}

@media (max-width: 1199px) {
  .congratulations-cash img {
    margin-left: 10px;
    width: 32px;
  }
}

.btn-congratulations {
  width: 7.8125vw;
  height: 2.6041666667vw;
  font: 1.25vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .btn-congratulations {
    width: 140px;
    height: 42px;
    font: 18px "Bebas Neue";
  }
}

.popup-nickname {
  color: #ff26ef;
  font: 1.5625vw "Bebas Neue";
  margin-bottom: 1.5625vw;
  text-align: center;
}

@media (max-width: 1199px) {
  .popup-nickname {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

.title-results {
  font: 1.09375vw "Bebas Neue";
  margin-bottom: 0.78125vw;
  color: #9da3ac;
  letter-spacing: 1px;
}

@media (max-width: 1199px) {
  .title-results {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.box-highscores {
  display: flex;
  flex-wrap: wrap;
}

.box-highscores__scroll {
  max-height: 400px;
  overflow-y: auto;
  scrollbar-color: #ff26ef #323c48;
  scrollbar-width: thin;
}

.box-highscores__scroll::-webkit-scrollbar {
  width: 3px;
}

.box-highscores__scroll::-webkit-scrollbar-track {
  background-color: #323c48;
}

.box-highscores__scroll::-webkit-scrollbar-thumb {
  background-color: #ff26ef;
}

.game-result {
  display: flex;
  align-items: center;
  margin-bottom: 1.1458333333vw;
  padding-right: 0.78125vw;
  width: 50%;
}

@media (max-width: 1199px) {
  .game-result {
    margin-bottom: 12px;
    padding-right: 10px;
  }
}

@media (max-width: 567px) {
  .game-result {
    width: 100%;
    padding-right: 0;
  }
}

.game-result__img {
  flex: 0 0 3.28125vw;
  width: 3.28125vw;
  height: 3.28125vw;
  border-radius: 0.78125vw;
  overflow: hidden;
  box-shadow: 0 0.5208333333vw 1.8229166667vw rgba(16, 14, 20, 0.45);
}

@media (max-width: 1199px) {
  .game-result__img {
    flex: 0 0 46px;
    width: 46px;
    height: 46px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(16, 14, 20, 0.45);
  }
}

.game-result__img img {
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.game-result__info {
  font-family: "Bebas Neue";
  padding-left: 0.78125vw;
  letter-spacing: 1px;
  width: 80%;
}

@media (max-width: 1199px) {
  .game-result__info {
    padding-left: 10px;
  }
}

.game-result__info h3 {
  font-size: 0.9375vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1199px) {
  .game-result__info h3 {
    font-size: 16px;
  }
}

.game-result__info p {
  color: #3cdd4a;
}

.popup-account__nickaname {
  margin-bottom: 1.1979166667vw;
}

@media (max-width: 1199px) {
  .popup-account__nickaname {
    margin-bottom: 15px;
  }
}

.popup-account__nickaname p {
  font-size: 0.625vw;
  color: #9da3ac;
}

@media (max-width: 1199px) {
  .popup-account__nickaname p {
    font-size: 12px;
  }
}

.change-nickname {
  position: relative;
}

.change-nickname input {
  width: 100%;
  display: block;
  font: 1.5625vw "Bebas Neue";
  background: transparent;
  text-align: center;
  color: #ff26ef;
  letter-spacing: 1px;
}

@media (max-width: 1199px) {
  .change-nickname input {
    font-size: 22px;
  }
}

.btn-change__nickname {
  position: absolute;
  cursor: pointer;
  top: -1.0416666667vw;
  right: 30%;
  width: 1.4583333333vw;
  height: 1.4583333333vw;
  border-radius: 50%;
  font-size: 0.7291666667vw;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #90969f;
  transition: all .3s ease-in-out;
}

@media (max-width: 1199px) {
  .btn-change__nickname {
    top: -18px;
    width: 22px;
    height: 22px;
    font-size: 14px;
  }
}

.btn-change__nickname:hover {
  background-color: #ff26ef;
  color: #fff;
}

.title-balance {
  font: 1.25vw "Bebas Neue";
  letter-spacing: 1.5px;
  margin-bottom: 0.4166666667vw;
}

@media (max-width: 1199px) {
  .title-balance {
    font-size: 18px;
    margin-bottom: 8px;
  }
}

.wrap-balance {
  display: flex;
  justify-content: space-between;
  width: 380px;
  margin: 0 auto 1.8229166667vw;
}

@media (max-width: 1199px) {
  .wrap-balance {
    margin: 0 auto 15px;
  }
}

@media (max-width: 567px) {
  .wrap-balance {
    width: 100%;
    flex-wrap: wrap;
  }
}

.box-balance-col {
  width: 9.1145833333vw;
}

@media (max-width: 1199px) {
  .box-balance-col {
    width: 45%;
  }
}

@media (max-width: 567px) {
  .box-balance-col {
    width: 100%;
  }
}

.box-balance {
  height: 5.2083333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 0.78125vw;
  border: 0.2604166667vw solid #324a74;
  background-color: rgba(255, 255, 255, 0.05);
}

@media (max-width: 1199px) {
  .box-balance {
    height: 100px;
    border-radius: 15px;
    border-width: 5px;
  }
}

@media (max-width: 567px) {
  .box-balance {
    margin-bottom: 10px;
  }
}

.box-balance img {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.box-balance h4 {
  font: 0.78125vw "Raleway Medium";
}

@media (max-width: 1199px) {
  .box-balance h4 {
    font-size: 15px;
  }
}

.box-balance p {
  display: flex;
  align-items: center;
  font: 1.5625vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .box-balance p {
    font-size: 26px;
  }
}

.box-balance p i {
  width: 0.9375vw;
  height: 0.9375vw;
  color: #fff;
  font-size: 0.5208333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .box-balance p i {
    width: 18px;
    height: 18px;
    font-size: 10px;
    margin-left: 5px;
  }
}

.box-balance__coin p {
  color: #f0b500;
}

.box-balance__coin p i {
  background-color: #f0b500;
}

.box-balance__coin img {
  left: -0.2604166667vw;
  width: 1.3020833333vw;
}

@media (max-width: 1199px) {
  .box-balance__coin img {
    left: -5px;
    width: 25px;
  }
}

.box-balance__money img {
  right: -0.5208333333vw;
  width: 1.7708333333vw;
}

@media (max-width: 1199px) {
  .box-balance__money img {
    right: -10px;
    width: 34px;
  }
}

.box-balance__money p {
  color: #3cdd4a;
}

.box-balance__money p i {
  background-color: #3cdd4a;
}

.popup-account__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .popup-account__buttons {
    margin-bottom: 10px;
  }
}

.popup-account__buttons a {
  margin-bottom: 0.78125vw;
  width: 8.1770833333vw;
  height: 2.2916666667vw;
  font: 0.78125vw "Raleway Medium";
}

@media (max-width: 1199px) {
  .popup-account__buttons a {
    margin-bottom: 8px;
    width: 140px;
    height: 42px;
    font: 14px "Raleway Medium";
  }
}

.popup-account__links {
  display: flex;
  justify-content: space-between;
  margin: 0 auto 1.5625vw;
  width: 19.7916666667vw;
}

@media (max-width: 1199px) {
  .popup-account__links {
    margin: 0 auto 15px;
    width: 380px;
  }
}

@media (max-width: 567px) {
  .popup-account__links {
    width: 100%;
    flex-direction: column;
  }
}

.popup-account__links a {
  text-decoration: underline;
  font-size: 0.7291666667vw;
  color: #677281;
}

@media (max-width: 1199px) {
  .popup-account__links a {
    font-size: 14px;
  }
}

@media (max-width: 567px) {
  .popup-account__links a {
    margin-bottom: 8px;
  }
}

.popup-account__links a:hover {
  text-decoration: none;
}

.link-bottom a {
  font: 1.25vw "Bebas Neue";
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 1199px) {
  .link-bottom a {
    font-size: 20px;
  }
}

.link-bottom a:hover {
  text-decoration: none;
}

.balance-note {
  color: #f0b500;
  font: 0.5729166667vw "Raleway Medium";
  margin-top: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .balance-note {
    font-size: 11px;
    margin-top: 5px;
  }
}

.withdraw-img {
  width: 2.4479166667vw;
  margin: 0 auto 0.6770833333vw;
}

@media (max-width: 1199px) {
  .withdraw-img {
    width: 35px;
    margin-bottom: 10px;
  }
}

.withdraw-money h2 {
  font: 1.5625vw "Bebas Neue";
  color: #f0b500;
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .withdraw-money h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }
}

.withdraw-money h3 {
  font: 1.25vw "Bebas Neue";
  margin-bottom: 0.5208333333vw;
  letter-spacing: 1.5px;
}

@media (max-width: 1199px) {
  .withdraw-money h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.withdraw-money .btn__gradient {
  width: 8.1770833333vw;
  height: 2.3958333333vw;
  font: 0.78125vw "Raleway Medium";
  margin-bottom: 0.6770833333vw;
}

@media (max-width: 1199px) {
  .withdraw-money .btn__gradient {
    width: 140px;
    height: 42px;
    font: 14px "Raleway Medium";
    margin-bottom: 8px;
  }
}

.withdraw-money .btn__gray {
  width: 11.9791666667vw;
  height: 2.3958333333vw;
  font: 0.78125vw "Raleway Medium";
  margin-bottom: 0.6770833333vw;
}

@media (max-width: 1199px) {
  .withdraw-money .btn__gray {
    width: 180px;
    height: 42px;
    font: 14px "Raleway Medium";
    margin-bottom: 8px;
  }
}

.withdraw-pay {
  display: flex;
  justify-content: space-between;
}

.withdraw-balance {
  width: 9.1145833333vw;
  height: 5.2083333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.78125vw;
  border: 0.2604166667vw solid #324a74;
  background-color: rgba(255, 255, 255, 0.05);
  margin: 0 auto 1.5625vw;
}

@media (max-width: 1199px) {
  .withdraw-balance {
    width: 175px;
    height: 100px;
    border-radius: 15px;
    border-width: 5px;
    margin: 0 auto 15px;
  }
}

.withdraw-balance h4 {
  font: 0.78125vw "Raleway Medium";
  margin-bottom: 0.4166666667vw;
}

@media (max-width: 1199px) {
  .withdraw-balance h4 {
    font-size: 15px;
    margin-bottom: 8px;
  }
}

.withdraw-balance p {
  display: flex;
  align-items: center;
  font: 1.5625vw "Bebas Neue";
  color: #3cdd4a;
}

@media (max-width: 1199px) {
  .withdraw-balance p {
    font-size: 26px;
  }
}

.withdraw-balance p img {
  margin-left: 0.5208333333vw;
  width: 1.7708333333vw;
}

@media (max-width: 1199px) {
  .withdraw-balance p img {
    margin-left: 8px;
    width: 30px;
  }
}

.withdraw-history {
  text-align: left;
  margin-bottom: 2.0833333333vw;
  padding: 1.3020833333vw;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .withdraw-history {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 10px;
  }
}

.withdraw-history table {
  width: 100%;
}

.withdraw-history th {
  font-family: "Avenir Next Cyr Demi";
  color: #8d95a1;
  text-transform: uppercase;
  padding-bottom: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .withdraw-history th {
    padding-bottom: 5px;
  }
}

.withdraw-history td {
  font-size: 0.7291666667vw;
  color: #677281;
  padding: 0.2604166667vw 0;
}

@media (max-width: 1199px) {
  .withdraw-history td {
    padding: 5px 0;
    font-size: 14px;
  }
}

.withdraw-history td:first-child {
  width: 70%;
}

@media (max-width: 567px) {
  .withdraw-history td:first-child {
    width: 50%;
  }
}

.btn-history {
  width: 6.9270833333vw;
  height: 2.8125vw;
  font: 1.25vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .btn-history {
    width: 100px;
    height: 42px;
    font: 18px "Bebas Neue";
  }
}

.cash-prizes .popup-title {
  text-align: center;
}

.cash-prizes .popup-title span {
  color: #3cdd4a;
}

.cash-prizes .table-menu {
  margin-bottom: 0.5208333333vw;
  justify-content: center;
}

@media (max-width: 1199px) {
  .cash-prizes .table-menu {
    margin-bottom: 10px;
  }
}

.cash-prizes .table-menu li {
  font-size: 1.09375vw;
  width: 30%;
}

@media (max-width: 1199px) {
  .cash-prizes .table-menu li {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .cash-prizes .table-menu li {
    width: 50%;
  }
}

.cash-prizes .table-menu span {
  padding-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .cash-prizes .table-menu span {
    padding-bottom: 10px;
  }
}

.cash-prizes .table-menu span:before {
  height: 0.15625vw;
}

@media (max-width: 1199px) {
  .cash-prizes .table-menu span:before {
    height: 3px;
  }
}

.cash-prizes__row {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .cash-prizes__row {
    flex-direction: column;
  }
}

.cash-prizes__l {
  flex: 0 0 12.6041666667vw;
}

@media (max-width: 1199px) {
  .cash-prizes__l {
    flex: 0 0 242px;
  }
}

@media (max-width: 767px) {
  .cash-prizes__l {
    flex: 0 0 auto;
    width: 242px;
  }
}

.cash-prizes__r {
  padding-left: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .cash-prizes__r {
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .cash-prizes__r {
    padding-left: 0;
    margin-bottom: 20px;
  }
}

.cash-prizes__text {
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .cash-prizes__text {
    margin-bottom: 10px;
  }
}

.cash-prizes__text h2 {
  font: 1.5625vw "Bebas Neue";
  letter-spacing: 1.5px;
}

@media (max-width: 1199px) {
  .cash-prizes__text h2 {
    font-size: 24px;
  }
}

.cash-prizes__text h3 {
  font: 1.0416666667vw "Bebas Neue";
  color: #636973;
  margin-bottom: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .cash-prizes__text h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }
}

.cash-prizes__text ul li {
  margin-bottom: 0.2604166667vw;
  color: #9da3ac;
  font-size: 0.7291666667vw;
}

@media (max-width: 1199px) {
  .cash-prizes__text ul li {
    margin-bottom: 5px;
    font-size: 14px;
  }
}

.cash-prizes__text ul li:before {
  content: '- ';
}

.active-prize {
  display: flex;
  align-items: center;
}

.active-prize__text {
  margin-right: 0.9375vw;
}

@media (max-width: 1199px) {
  .active-prize__text {
    margin-right: 10px;
  }
}

.active-prize__text h3 {
  font: 0.7291666667vw "Bebas Neue";
  letter-spacing: 1.5px;
}

@media (max-width: 1199px) {
  .active-prize__text h3 {
    font-size: 14px;
  }
}

.active-prize__text p {
  font-size: 0.7291666667vw;
  color: #979da6;
}

@media (max-width: 1199px) {
  .active-prize__text p {
    font-size: 14px;
  }
}

.active-prize__text p i {
  color: #ff26ef;
}

.active-prize__bon {
  display: flex;
  align-items: center;
  font: 1.5625vw "Bebas Neue";
  color: #3cdd4a;
}

@media (max-width: 1199px) {
  .active-prize__bon {
    font-size: 24px;
  }
}

.active-prize__bon img {
  width: 1.8229166667vw;
  margin-left: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .active-prize__bon img {
    width: 24px;
    margin-left: 5px;
  }
}

.cash-bon {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .cash-bon {
    flex-direction: column;
  }
}

.cash-prizes__box {
  flex: 0 0 13.5416666667vw;
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .cash-prizes__box {
    flex: 0 0 260px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .cash-prizes__box {
    flex: 0 0 auto;
  }
}

.cash-prizes__bonus {
  width: 7.2916666667vw;
  height: 4.3229166667vw;
  border-radius: 0.78125vw;
  border: 0.2604166667vw solid #324a74;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.625vw;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .cash-prizes__bonus {
    width: 140px;
    height: 83px;
    border-radius: 15px;
    border-width: 5px;
    padding: 12px;
  }
}

.cash-prizes__bonus p {
  font: 1.5625vw "Bebas Neue";
  color: #3cdd4a;
  display: flex;
  align-items: center;
}

@media (max-width: 1199px) {
  .cash-prizes__bonus p {
    font-size: 30px;
  }
}

.cash-prizes__bonus p img {
  width: 1.8229166667vw;
  margin-left: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .cash-prizes__bonus p img {
    width: 35px;
    margin-left: 5px;
  }
}

.cash-prizes__bonus h5 {
  font: 0.7291666667vw "Raleway Medium";
}

@media (max-width: 1199px) {
  .cash-prizes__bonus h5 {
    font-size: 14px;
  }
}

.cash-places {
  display: flex;
  flex-wrap: wrap;
}

.place {
  width: 4.1666666667vw;
  margin: 0 0.78125vw 0.78125vw 0;
}

@media (max-width: 1199px) {
  .place {
    width: 80px;
    margin: 0 15px 15px 0;
  }
}

.place p {
  font: 1.0416666667vw "Bebas Neue";
  color: #3cdd4a;
  display: flex;
  align-items: center;
}

@media (max-width: 1199px) {
  .place p {
    font-size: 20px;
  }
}

.place p img {
  margin-left: 0.2604166667vw;
  width: 1.3020833333vw;
}

@media (max-width: 1199px) {
  .place p img {
    margin-left: 5px;
    width: 25px;
  }
}

.place h5 {
  font-size: 0.7291666667vw;
}

@media (max-width: 1199px) {
  .place h5 {
    font-size: 14px;
  }
}

.tournament-info {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5625vw;
  text-align: center;
}

@media (max-width: 1199px) {
  .tournament-info {
    margin-top: 15px;
  }
}

.tournament-info__box h3 {
  font: 0.9375vw "Bebas Neue";
  letter-spacing: 1.5px;
}

@media (max-width: 1199px) {
  .tournament-info__box h3 {
    font-size: 16px;
  }
}

.tournament-info__box p {
  display: flex;
  justify-content: center;
  align-items: center;
  font: 1.5625vw "Bebas Neue";
  color: #3cdd4a;
}

@media (max-width: 1199px) {
  .tournament-info__box p {
    font-size: 24px;
  }
}

.tournament-info__box p img {
  width: 1.6666666667vw;
  margin-left: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .tournament-info__box p img {
    width: 24px;
    margin-left: 8px;
  }
}

.cash-prizes__bottom {
  margin-top: 1.3020833333vw;
  text-align: center;
}

@media (max-width: 1199px) {
  .cash-prizes__bottom {
    margin-top: 15px;
  }
}

.cash-prizes__bottom .btn {
  width: 10.4166666667vw;
  height: 2.8645833333vw;
  font: 1.25vw "Bebas Neue";
  margin-bottom: 0.78125vw;
}

@media (max-width: 1199px) {
  .cash-prizes__bottom .btn {
    width: 180px;
    height: 42px;
    font: 18px "Bebas Neue";
    margin-bottom: 10px;
  }
}

.quick-tip {
  margin: 0 auto 30px;
  border-radius: 0.78125vw;
  max-width: 22.5vw;
  padding: 1.3020833333vw 1.0416666667vw;
  background-color: rgba(0, 0, 0, 0.1);
  color: #677281;
  font-size: 0.7291666667vw;
}

@media (max-width: 1199px) {
  .quick-tip {
    border-radius: 15px;
    max-width: 432px;
    padding: 20px 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

.quick-tip h2 {
  font-family: "Avenir Next Cyr Demi";
  margin-bottom: 0.78125vw;
  color: #8d95a1;
  font-size: 0.8333333333vw;
}

@media (max-width: 1199px) {
  .quick-tip h2 {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.live-icon {
  position: absolute;
  top: 0.6770833333vw;
  left: 0.6770833333vw;
  width: 5.6770833333vw;
  animation: 1s linear pulse infinite;
}

@media (max-width: 1199px) {
  .live-icon {
    top: 10px;
    left: 10px;
    width: 109px;
  }
}

@media (max-width: 567px) {
  .live-icon {
    width: 50px;
  }
}

.popup-timer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 567px) {
  .popup-timer {
    flex-direction: column;
  }
}

.popup-timer__mb25 {
  margin-bottom: 1.3020833333vw;
}

@media (max-width: 1199px) {
  .popup-timer__mb25 {
    margin-bottom: 15px;
  }
}

.popup-timer h5 {
  font: 0.9375vw "Bebas Neue";
  color: rgba(255, 255, 255, 0.5);
  margin: -0.5208333333vw 0.78125vw 0 0;
}

@media (max-width: 1199px) {
  .popup-timer h5 {
    font-size: 16px;
    margin: -10px 10px 0 0;
  }
}

@media (max-width: 567px) {
  .popup-timer h5 {
    margin: 0 0 10px;
  }
}

.popup-timer .timer .timer-num span {
  width: 1.6666666667vw;
  height: 2.1875vw;
  font-size: 1.3541666667vw;
  margin: 0 0.078125vw 0.2604166667vw;
}

@media (max-width: 1199px) {
  .popup-timer .timer .timer-num span {
    width: 32px;
    height: 42px;
    font-size: 26px;
    margin: 0 1.5px 5px;
  }
}

.popup-timer .timer .timer-box {
  margin: 0 0.1041666667vw;
}

@media (max-width: 1199px) {
  .popup-timer .timer .timer-box {
    margin: 0 2px;
  }
}

.popup-timer .timer .timer-box p {
  font-size: 0.46875vw;
}

@media (max-width: 1199px) {
  .popup-timer .timer .timer-box p {
    font-size: 9px;
  }
}

.popup-subtitle {
  font: 1.09375vw "Bebas Neue";
  margin-bottom: 0.5208333333vw;
  color: #9da3ac;
}

@media (max-width: 1199px) {
  .popup-subtitle {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.style-slider .slick-disabled {
  display: none !important;
}

.style-slider .slider-arrow__prev {
  left: -1.3541666667vw;
}

@media (max-width: 1199px) {
  .style-slider .slider-arrow__prev {
    left: 0;
  }
}

.style-slider .slider-arrow__next {
  right: -1.3541666667vw;
}

@media (max-width: 1199px) {
  .style-slider .slider-arrow__next {
    right: 0;
  }
}

.slider-place {
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .slider-place {
    margin-bottom: 10px;
  }
}

.slider-place__col {
  padding: 0 0.46875vw;
}

@media (max-width: 1199px) {
  .slider-place__col {
    padding: 5px;
  }
}

.slider-place__item {
  background-color: #273545;
  border-radius: 0.78125vw;
  padding: 0.4166666667vw;
}

@media (max-width: 1199px) {
  .slider-place__item {
    border-radius: 15px;
    padding: 8px;
  }
}

.slider-place__t {
  text-align: left;
  font-family: "Avenir Next Cyr Demi";
  color: #3cdd4a;
}

.slider-place__img {
  width: 1.8229166667vw;
  margin: 0 auto 0.2604166667vw;
}

@media (max-width: 1199px) {
  .slider-place__img {
    width: 35px;
    margin-bottom: 5px;
  }
}

.slider-place__money {
  font: 1.6145833333vw "Bebas Neue";
  color: #f0b500;
}

@media (max-width: 1199px) {
  .slider-place__money {
    font-size: 31px;
  }
}

.slider-place__money span {
  color: #fff;
}

.slider-users {
  margin-bottom: 0.78125vw;
}

@media (max-width: 1199px) {
  .slider-users {
    margin-bottom: 10px;
  }
}

.slider-users__col {
  padding: 2.0833333333vw 0.2604166667vw 0;
}

@media (max-width: 1199px) {
  .slider-users__col {
    padding: 40px 5px 0;
  }
}

.user-item {
  position: relative;
  padding: 1.875vw 0.5208333333vw 0.5208333333vw;
  background-color: #1b2735;
  border-radius: 0.5208333333vw;
  border: 0.2083333333vw solid #28416f;
  height: 5.8333333333vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 1199px) {
  .user-item {
    padding: 36px 10px 10px;
    border-radius: 10px;
    border-width: 4px;
    height: 112px;
  }
}

.user-item.user-item__my {
  background-color: #273545;
}

.user-item.user-item__my .user-item__name {
  color: #f0b500;
  font-size: 1.5625vw;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .user-item.user-item__my .user-item__name {
    font-size: 30px;
  }
}

.user-item__photo {
  position: absolute;
  top: 0;
  left: 50%;
  width: 3.28125vw;
  height: 3.28125vw;
  transform: translate(-50%, -50%);
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .user-item__photo {
    width: 63px;
    height: 63px;
    margin-bottom: 10px;
  }
}

.user-item__photo img {
  border-radius: 50%;
  max-width: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.user-item__label {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.3541666667vw;
  height: 1.3541666667vw;
  position: absolute;
  top: -0.625vw;
  left: -0.3125vw;
  background-color: #9037d5;
  font: 0.5729166667vw "Avenir Next Cyr Demi";
}

@media (max-width: 1199px) {
  .user-item__label {
    width: 26px;
    height: 26px;
    top: -12px;
    left: -6px;
    font-size: 11px;
  }
}

.user-item__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: 1.0416666667vw "Bebas Neue";
  margin-bottom: 0.2604166667vw;
}

@media (max-width: 1199px) {
  .user-item__name {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

.user-item__points {
  color: #3cdd4a;
  font: 0.7291666667vw "Avenir Next Cyr Demi";
}

@media (max-width: 1199px) {
  .user-item__points {
    font-size: 14px;
  }
}

.btn-play {
  width: 6.40625vw;
  height: 2.34375vw;
  font: 1.0416666667vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .btn-play {
    width: 123px;
    height: 45px;
    font: 20px "Bebas Neue";
  }
}

.btn-play-again {
  width: 8.3333333333vw;
  height: 2.34375vw;
  font: 1.0416666667vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .btn-play-again {
    width: 160px;
    height: 45px;
    font: 20px "Bebas Neue";
  }
}

.tournament-scored {
  font: 1.875vw "Bebas Neue";
  margin-bottom: 0.78125vw;
}

@media (max-width: 1199px) {
  .tournament-scored {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.tournament-scored .green {
  color: #3cdd4a;
}

.tournament-scored .yellow {
  color: #f0b500;
}

.game-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.0416666667vw 2.0833333333vw;
  background-color: #2d3645;
}

@media (max-width: 1199px) {
  .game-header {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .game-header {
    justify-content: space-between;
  }
}

.game-btn {
  width: 3.125vw;
  height: 3.125vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9375vw;
  cursor: pointer;
  border: 0.2604166667vw solid;
  color: #fff;
  transition: all .3s ease-in-out;
}

@media (max-width: 1199px) {
  .game-btn {
    width: 40px;
    height: 40px;
    font-size: 16px;
    border-width: 4px;
  }
}

.game-btn:hover {
  box-shadow: none;
}

.game-btn__pause {
  background-color: #fec30a;
  border-color: #ffc806;
  box-shadow: 0 0 1.09375vw #fec30a;
}

@media (max-width: 1199px) {
  .game-btn__pause {
    box-shadow: 0 0 16px #fec30a;
  }
}

@media (max-width: 767px) {
  .game-btn__pause {
    order: 2;
  }
}

.game-btn__volume {
  background-color: #009fe2;
  border-color: #00aded;
  box-shadow: 0 0 1.09375vw #009fe2;
}

@media (max-width: 1199px) {
  .game-btn__volume {
    box-shadow: 0 0 16px #009fe2;
  }
}

@media (max-width: 767px) {
  .game-btn__volume {
    order: 3;
  }
}

.game-btn__close {
  background-color: #e1395c;
  border-color: #dd2049;
  box-shadow: 0 0 1.09375vw #e1395c;
}

@media (max-width: 1199px) {
  .game-btn__close {
    box-shadow: 0 0 16px #e1395c;
  }
}

@media (max-width: 767px) {
  .game-btn__close {
    order: 3;
  }
}

.game-score {
  flex: 1 0 auto;
  font: 2.5vw "Bebas Neue";
  padding-left: 7.1354166667vw;
}

@media (max-width: 1199px) {
  .game-score {
    font-size: 26px;
    padding-left: 40px;
  }
}

@media (max-width: 767px) {
  .game-score {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}

.game-score span {
  color: #3cdd4a;
}

.game-score.text-center {
  padding: 0;
}

.game-timer {
  margin-right: 8.3333333333vw;
}

@media (max-width: 1199px) {
  .game-timer {
    margin-right: 40px;
  }
}

@media (max-width: 767px) {
  .game-timer {
    margin-right: 0;
    width: 100%;
  }
}

.game-timer .timer-num span {
  background: url("../images/other/timer-bg-dark.png") center/contain no-repeat;
}

.current-info p {
  font: 1.875vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .current-info p {
    font-size: 24px;
  }
}

.current-score span {
  color: #3cdd4a;
}

.current-position {
  margin-bottom: 1.0416666667vw;
}

@media (max-width: 1199px) {
  .current-position {
    margin-bottom: 10px;
  }
}

.current-position span {
  color: #f0b500;
}

.btn-continue {
  width: 8.6979166667vw;
  height: 2.8645833333vw;
  font: 1.25vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .btn-continue {
    width: 140px;
    height: 42px;
    font: 18px "Bebas Neue";
  }
}

.reward-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.6770833333vw;
}

@media (max-width: 1199px) {
  .reward-info {
    margin-bottom: 10px;
  }
}

.reward-info img {
  width: 1.875vw;
  margin-left: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .reward-info img {
    width: 24px;
    margin-left: 8px;
  }
}

.end-tournament .tournament-scored {
  margin-bottom: 0;
}

.tournament-win {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.3020833333vw;
}

@media (max-width: 1199px) {
  .tournament-win {
    margin-bottom: 15px;
  }
}

.tournament-win img {
  width: 2.2916666667vw;
  margin-left: 0.46875vw;
}

@media (max-width: 1199px) {
  .tournament-win img {
    width: 26px;
    margin-left: 8px;
  }
}

.btn-collect {
  width: 8.8541666667vw;
  height: 2.8645833333vw;
  font: 1.25vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .btn-collect {
    width: 140px;
    height: 42px;
    font: 18px "Bebas Neue";
  }
}

.highscore {
  margin-top: 0.8854166667vw;
  font-size: 0.625vw;
  color: #9da3ac;
}

@media (max-width: 1199px) {
  .highscore {
    margin-top: 10px;
    font-size: 12px;
  }
}

.highscore span {
  color: #3cdd4a;
}

.lucky-coins__title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5208333333vw;
  font: 2.6041666667vw "Bebas Neue";
}

@media (max-width: 1199px) {
  .lucky-coins__title {
    font-size: 32px;
    margin-bottom: 10px;
  }
}

.lucky-coins__title img {
  width: 1.8229166667vw;
  margin-left: 0.5208333333vw;
}

@media (max-width: 1199px) {
  .lucky-coins__title img {
    width: 24px;
    margin-left: 8px;
  }
}
